<form [formGroup]="formDashboard" (submit)="PesquisarVisitas()">
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro" >
    <div class="container">
      <div class="grupo-filter">
        <p><b>Polo</b></p>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="OnChangePolo()"
          [ngClass]="{'is-invalid': submitted && form.codigoPolo.errors}"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="onChangeEscola()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Chamados"
          title="Pesquisar Chamados"
        />
      </button>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Painel de Atividade Psicólogo</h2>

      <div class="periodo">
        <p><b>Pesquisar por período:</b></p>
        <div class="periodo-input">
          <input
          type="date"
          name="dataInicial"
          />
          &nbsp;-&nbsp;
          <input
          type="date"
          name="dataFinal"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h3 class="sub-titulo-dash">
        Listagem de Visita
      </h3>

      <div class="conteudo-legenda">
        <p><b>Legenda</b></p>
        <div class="conteudo-infor">
          <p>
            <i class="fa fa-eye" aria-hidden="true"></i>
            Visualizar
          </p>
          <p>
            <i class="fa-regular fa-square-check" aria-hidden="true"></i>
            Aprovação
          </p>
          <p>
            <i class="fa fa-pencil" aria-hidden="true"></i>
            Edição
          </p>
          @if(userLogado?.codigoPerfil === 1477){
            <p>
              <i class="fa fa-trash" aria-hidden="true"></i>
              Excluir
            </p>
          }
        </div>
      </div>
    </div>
  </section>

  <!-- RESULTADOS -->
  <section class="conteudo-principal">

    <div class="container">
      <div class="grid-datatable" *ngIf="this.submitted">
        <table
          datatable
          [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger"
          class="row-border"
          aria-describedby="dashboard"
        >
          <thead>
            <tr>
              <th>Cdg. Visita</th>
              <th>Tipo visita</th>
              <th>N° Chamado</th>
              <th>Data</th>
              <th>Duração</th>
              <th>Aprovado?(PEC)</th>
              <th>Aprovado?(Fiscal)</th>
              <th>PEC<br>Estrelas e comentarios</th>
              <th>Fiscal<br>Estrelas e comentarios</th>
              <th>Psicólogo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
           <tr *ngFor="let item of lstAtividade">
              <td>{{item.codigoAtividadePsicologo}}</td>
              <td>{{item.ds_TP_ACAO_ATIVIDADE}}</td>
              <td> {{item.codigoChamado}} </td>
              <td> {{getDate(item.dataAtividadePsicologo)}} </td>
              <td> {{item.timeAtividadePsicologo}} </td>
              <td>{{ getAprovacaoStatus(item.statusAprovacaoPEC!) }}</td>
              <td>{{ getAprovacaoStatus(item.statusAprovacaoFiscal!) }}</td>
              <td >
                <div class="icons-star">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==0"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==1"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==1"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==2"><img src="../../../../assets/img/icons/icon-star.svg"        alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==2"><img src="../../../../assets/img/icons/icon-star.svg"        alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==3"><img src="../../../../assets/img/icons/icon-star.svg"     alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoPEC==5">
                  <div class="icon-comentario">
                    <a href="javascript:void(0)" (click)="displayComentario(item.observacaoAprovacaoPEC || '', true)"><img src="../../../../assets/img/icons/icon-comentario.svg" alt="Icone Comment" title="Comentario"></a>
                  </div>
                </div>
              </td>
              <td >
                <div class="icons-star">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==0"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==1"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==1"><img src="../../../../assets/img/icons/icon-starVermelho.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==2"><img src="../../../../assets/img/icons/icon-starAmarelo.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==2"><img src="../../../../assets/img/icons/icon-star.svg"        alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==3"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==5">
                  <img src="../../../../assets/img/icons/icon-star.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==0"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==1"><img src="../../../../assets/img/icons/icon-star.svg"         alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==2"><img src="../../../../assets/img/icons/icon-star.svg"        alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==3"><img src="../../../../assets/img/icons/icon-star.svg"     alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==4"><img src="../../../../assets/img/icons/icon-starAzul.svg" alt="Icone estrela" *ngIf="item.estrelasAvaliacaoFiscal==5">
                  <div class="icon-comentario">
                    <a href="javascript:void(0)" (click)="displayComentario(item.observacaoAprovacaoFiscal || '', false)"><img src="../../../../assets/img/icons/icon-comentario.svg" alt="Icone Comment" title="Comentario"></a>
                  </div>
                </div>
              </td>
              <td> {{item.nome}}</td>
              <td>
                <div class="icones-acoes">
                  <a
                    href="javascript:void(0)" (click)="AbrirVisualizar(item)" >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>
                &nbsp;
                @if(userLogado?.codigoPerfil === 112 ||
                userLogado?.codigoPerfil == 1633 ||
                userLogado?.codigoPerfil === 1477){
                  <a
                    href="javascript:void(0)" (click)="AbrirAprovacao(item)">
                    <i class="fa-regular fa-square-check" aria-hidden="true"></i>
                  </a>
                  &nbsp;
                  @if(userLogado?.codigoPerfil === 1477){
                    <a *ngIf="this.userLogado?.funcionalidades?.includes(10)" href="javascript:void(0)" (click)="AbreFechaModalConfirmacao(item.codigoAtividadePsicologo)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  }
                }

              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </section>
</form>
  <!-- Modal -->
  <app-modal *ngIf="showModalConfirmacao">
    <div class="container-modal form-registro modal-envolvidos">
      <h1 class="title-ocorrencia">Excluir</h1>
      <div>
        <p>Deseja excluir registro?</p>
      </div>
      <div class="container-btns">
        <input type="button" (click)="AbreFechaModalConfirmacao()" class="btn_outline" value="Não"/>
        <input type="submit" (click)="ExcluirAtividadePsicologo()" class="btn_primary" value="Sim" />
      </div>
    </div>
  </app-modal>

  <app-modal *ngIf="showModalComentario">
    <div class="container-modal form-registro modal-envolvidos">
      @if(this.tipoPec){
      <h1 class="title-ocorrencia">Comentario PEC</h1>
      }@else{
      <h1 class="title-ocorrencia">Comentario Fiscal</h1>
      }
      <br>
      <div class="form-vista-psicologo">
        <p>{{ this.comentarioModal }}</p>
      </div>
      <br>
      <div class="container-btns">
        <input
        type="button"
        (click)="FecharModalComentario()"
        class="btn_outline"
        value="Fechar"
        />
      </div>
    </div>
  </app-modal>
