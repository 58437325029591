<form [formGroup]="formDashboard" (submit)="PesquisarNaoOcorrencias()">
    <!-- FILTRO ESCOLA -->
    <section class="section-filtro" >
      <div class="container">
        <div class="grupo-filter">
          <p><b>Diretoria</b></p>
          <ng-select
            [items]="diretorias"
            bindLabel="nomeDiretoria"
            bindValue="codigoDiretoria"
            placeholder="Selecione uma Diretoria"
            formControlName="codigoDiretoria"
            name="codigoDiretoria"
            [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
            (change)="getEscolas()"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
            <div *ngIf="form.codigoDiretoria.errors.required">
              * Diretoria é obrigatório
            </div>
          </div>
        </div>

        <div class="grupo-filter">
          <p><b>Escola</b></p>
          <ng-select
            [items]="escolas"
            bindLabel="nomeEscola"
            bindValue="codigoEscola"
            placeholder="Selecione uma Escola"
            formControlName="codigoEscola"
            name="codigoEscola"
            (change)="getTurnos()"
            [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
          >
          </ng-select>
        </div>

        <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
          <img
            src="../../../../assets/img/icons/icon-search.svg"
            alt="Pesquisar Chamados"
            title="Pesquisar Chamados"
          />
        </button>
      </div>
    </section>

    <!-- TITULO -->
    <section class="section-saudacao header-page-voltar">
      <div class="container">
        <h2 class="titulo-page">Painel Não Ocorrência</h2>

        <div class="periodo">
          <p><b>Pesquisar por período:</b></p>
          <div class="periodo-input">
            <input
            type="date"
            name="dataInicial"
            min="{{minDate}}"
            max="{{maxDate}}"
            (blur)="validaAno(this.form.dataInicio)"
            formControlName="dataInicio"
            />
            &nbsp;-&nbsp;
            <input
            type="date"
            name="dataFinal"
            min="{{minDate}}"
            max="{{maxDate}}"
            (blur)="validaAno(this.form.dataFim)"
            formControlName="dataFim"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- RESULTADOS -->
    <section class="conteudo-principal">

        <div class="container">

          <div class="conteudo-perfil" *ngIf="this.submitted && this.showPesquisa">

            <div class="conteudo-casos">
              <a
                href="javascript:void(0)"
                class="box-casos todos"
                (click)="PesquisarNaoOcorrencias()">
                <p class="number-casos">{{this.totalNaoOcorrencias}}</p>
                <p class="legenda-casos">Total de Chamados</p>
              </a>
            </div>

            <h3 class="sub-titulo-dash">
              Listagem de Não Ocorrencias
            </h3>

            <div class="grid-datatable">
              <table
                datatable
                [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger"
                class="row-border" aria-describedby="dashboard"
              >
                <thead>
                  <tr>
                    <th>Não Ocorrencia</th>
                    <th>Subcategoria</th>
                    <th>Descrição</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lstNaoOcorrencia">
                    <td>{{ item.codigoNaoOcorrencia}}</td>
                    <td>{{ item.nomeSubCategoria }}</td>
                    <td>{{ item.ds_NaoOcorrencia.length > 80 ? item.ds_NaoOcorrencia.slice(0, 80) + '...' : item.ds_NaoOcorrencia }}</td>
                    <td>{{ item.dt_NaoOcorrencia | date: 'dd/MM/yyyy' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

        </div>

    </section>
  </form>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Carregando ...</p>
  </ngx-spinner>
