<form [formGroup]="formRelatorios">

  <section class="header-page-relatorio">
    <div class="container">
      <h2>
        Relatório
      </h2>
      <section class="section-saudacao header-page-voltar">
        <div class="periodo">
            <p><b>Pesquisar por período:</b></p>
            <div class="periodo-input">
              <input
              type="date"
              name="dataInicial"
              min="{{minDate}}"
              max="{{maxDate}}"
              (blur)="validaAno(this.form.dataInicio)"
              formControlName="dataInicio"
              (change)="carregarCombos()"
              />
              &nbsp;-&nbsp;
              <input
              type="date"
              name="dataFinal"
              min="{{minDate}}"
              max="{{maxDate}}"
              (blur)="validaAno(this.form.dataFim)"
              formControlName="dataFim"
              (change)="carregarCombos()"
              />
            </div>
          </div>
      </section>
    </div>
  </section>

  <section class="section-filtro" >
      <div class="container">
        <div class="conteudo-filter">
          <div class="grupo-filter" *ngIf="ativarCamposDiretoriaEscola">
            <p><b>Diretoria</b></p>
            <ng-select
            [items]="diretorias"
            bindLabel="nomeDiretoria"
            bindValue="codigoDiretoria"
            placeholder="Selecione uma Diretoria"
            formControlName="codigoDiretoria"
            name="codigoDiretoria"
            (change)="getEscolas()"
            >
          </ng-select>
          </div>
          <div class="grupo-filter" *ngIf="ativarCamposDiretoriaEscola">
            <p><b>Escola</b></p>
            <ng-select
            [items]="escolas"
            bindLabel="nomeEscola"
            bindValue="codigoEscola"
            placeholder="Selecione uma Escola"
            formControlName="codigoEscola"
            name="codigoEscola"
            (change)="getCategorias()"
            >
          </ng-select>
          </div>
          <div class="grupo-filter" *ngIf="ativarCamposUsuario || ativarCamposPsicologo || ativarCamposOcorrenciasAtivas">
            <label for="select-categoria">Categoria</label>
            <ng-select
              [items]="categorias"
              bindLabel="descricaoSubCategoria"
              bindValue="codigoSubCategoria"
              groupBy="descricaoCategoria"
              placeholder="Selecione uma Sub Categoria"
              name="codigoSubCategoria"
              (change)="getPerfis()"
              formControlName="codigoSubCategoria">
              <ng-template ng-optgroup-tmp let-item="item">
                {{ item.descricaoCategoria || "Unnamed group" }}
              </ng-template>
            </ng-select>
          </div>
          <div class="grupo-filter" *ngIf="ativarCamposUsuario">
            <label for="select-perfil">Perfil</label>
            <ng-select
              [items]="perfis"
              bindLabel="descricaoPerfil"
              bindValue="codigoPerfil"
              placeholder="Selecione um Perfil"
              name="codigoPerfil"
              formControlName="codigoPerfil"
              (change)="getUsuarios()">
            </ng-select>
          </div>
          <div class="grupo-filter" *ngIf="ativarCamposUsuario">
            <label for="select-usuario">Usuario</label>
            <ng-select
              [items]="usuarios"
              bindLabel="nome"
              bindValue="cpf"
              placeholder="Selecione um Usuario"
              name="cpf"
              formControlName="cpf">
            </ng-select>
          </div>

          <div class="grupo-filter" *ngIf="ativarCamposOcorrenciasAtivas">
            <label for="filtro-ativo">Ativo</label>
            <ng-select
              [items]="flagAtivo"
              bindLabel="name"
              bindValue="id"
              class="select-ativo"
              placeholder="Selecione uma Opção"
              formControlName="codigoAtivo"
              name="codigoAtivo"
            >
            </ng-select>
          </div>


        </div>
      </div>
  </section>

  <section class="conteudo-relatorio">
    <div class="container">
      <div class="grup-input">
        <label>Selecione o Relatório:</label>
        <ng-select
          [items]="relatorios"
          bindLabel="name"
          bindValue="id"
          class="select-ativo"
          placeholder="Selecione um Relatório"
          formControlName="idRelatorio"
          name="idRelatorio"
          (change)="onOptionsSelected()">
        </ng-select>
      </div>
      <button class="btn-primary btn-relatorio" (click)="gerarRelatorio()">Gerar</button>
    </div>
  </section>

</form>


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
