<form [formGroup]="formPerfilManage" class="page-perfil">

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Adicionar Função ao Perfil</h2>
    </div>
  </section>

    <section class="section-filtro">
        <div class="container">
            <div class="grupo-filter">
                <p><b>Perfis</b></p>
                <ng-select
                [items]="perfis"
                bindLabel="descricaoPerfil"
                bindvalue="codigoPerfil"
                placeholder="Selecione um Perfil"
                formControlName="codigoPerfil"
                (change)="mostrarPerfisAcessos()"
                name="codigoPerfil"
                [ngClass]="{ 'is-invalid': submitted && form.codigoPerfil.errors }"
                >
                </ng-select>
            </div>
        </div>
    </section>

    <section class="section-table" *ngIf="PerfisAcesso && PerfisAcesso.length > 0">
      <div class="container">

        <!-- <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" aria-describedby="dashboard"> -->
        <table  class="row-border hover" aria-describedby="dashboard">
          <caption>Funcionalidades dos Perfis</caption>
          <thead>
            <tr>
              <th>Ativo</th>
              <th>Codigo Perfil</th>
              <th>Nome Perfil</th>
              <th>Codigo Funcionalidade</th>
              <th>Descrição Funcionalidade</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let perfilacesso of this.PerfisAcesso">
              <td>
                <label for="checkbox" class="form-control">
                <input
                  type="checkbox"
                  class="custom-checkbox"
                  name="checkbox"
                  [checked]="perfilacesso.flagAtivo == 1 ? true : false"
                  (click)="OnSalvar($event, perfilacesso)"
                />
                </label>
                </td>
              <td>{{perfilacesso.codigoPerfil}}</td>
              <td>{{perfilacesso.nomePerfil}}</td>
              <td>{{perfilacesso.codigoFuncionalidade}}</td>
              <td>{{perfilacesso.descricaoFuncionalidade}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>