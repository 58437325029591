import { ArquivoAzure } from "../arquivo/ArquivoAzure";

export class PocAtividade {
    cD_ATIVIDADE_POC : number;
    nM_POC: string;
    dT_ATIVIDADE_POC?: Date;
    tM_ATIVIDADE_POC?: string;
    cD_CHAMADO?: number;
    tP_ATIVIDADE_REALIZADA: number;
    cD_ENVOLVIDO: number;
    dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO?: string;
    dS_RESPOSTA_PERGUNTA_AVALIACAO_RESULTADO_ACAO?: string;
    dS_RESPOSTA_PERGUNTA_IMPACTO_ACAO_REALIZADA?: string;
    dS_SUB_CATEGORIA? : string;
    status: number;
    arquivos?: ArquivoAzure[];
    nM_ARQUIVO: string;
    sT_APROVACAO_DIRETORIA? :number;
    statuS_CHAMADO: string;
    atividadE_REALIZADA: string;
    tP_ACAO_ATIVIDADE: number;
    statuS_ENVOLVIDO : number;

    sT_APROVACAO_PEC?:number;
    oB_APROVACAO_PEC?:string;
    oB_APROVACAO_DIRETORIA?:string;
    nM_PERFIL_APROVACAO_PEC?:string;
    estrelaS_AVALIACAO_DIRETORIA?:number;
    estrelaS_AVALIACAO_PEC ?:number;
    dT_APROVACAO_PEC?:Date;
    dS_RESULTADO_AVALIACAO_PEC?:string;
    cD_USUARIO_APROVACAO_PEC?:number;
    cD_PERFIL_APROVACAO_PEC?:number;
}
