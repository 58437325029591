import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { ConvivaApi } from "src/app/conviva-api";
import { Observable, map } from "rxjs";
import { DefaultResponse } from "../models/DefaultResponse";
import { BuscarEnvolvidos } from "../models/envolvido/BuscarEnvolvidos";

@Injectable({
    providedIn: 'root'
})

export class EnvolvidoService {
    private api = ConvivaApi;

    constructor(
        private http: HttpClient,
        private router: Router)
        {}

    public GetEnvolvidosPorTurma(parametros:BuscarEnvolvidos):Observable<DefaultResponse>{
        return this.http.post<DefaultResponse>(`${this.api.Envolvido.getEnvolvidosPorTurma}`,parametros)
        .pipe(map((response: DefaultResponse) => response));
    }


    public GetEnvolvidosPorChamado(codigoChamado: number):Observable<DefaultResponse>{
        return this.http.get<DefaultResponse>(`${this.api.Envolvido.getEnvolvidosPorChamado}?codigoChamado=${codigoChamado}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public GetEnvolvidosPorAtividadePOC(codigoAtividade: number):Observable<DefaultResponse>{
        return this.http.get<DefaultResponse>(`${this.api.Envolvido.getEnvolvidosPorAtividadePOC}?codigoAtividade=${codigoAtividade}`)
        .pipe(map((response: DefaultResponse) => response));
    }

    public GetEnvolvidosPorAtividadePsicologo(codigoAtividade: number):Observable<DefaultResponse>{
        return this.http.get<DefaultResponse>(`${this.api.Envolvido.getEnvolvidosPorAtividadePsicologo}?codigoAtividade=${codigoAtividade}`)
        .pipe(map((response: DefaultResponse) => response));
    }

}
