//Principais
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { formatDate } from '@angular/common';
import myLocalePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { ListarCalendario } from "src/app/shared/models/relatorio/listar-calendario";
import { Consolidado } from "src/app/shared/models/relatorio/Consolidado";
import { NaoOcorrencia } from "src/app/shared/models/nao-ocorrencia/NaoOcorrencia";

//Services
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { EncaminhamentoService } from "src/app/shared/services/encaminhamento.service";
import { AlunosRecorrentes } from "src/app/shared/models/aluno/AlunosRecorrentes";
import { ListarEncaminhados } from "src/app/shared/models/chamados/listar-encaminhados";
import { lastValueFrom } from "rxjs";
import { RelatorioService } from "src/app/shared/services/relatorio.service";
import { NaoOcorrenciaService } from "src/app/shared/services/nao-ocorrencia.service";
import { BaseComponent } from "components/base/base.component";
import { FiltroPadrao } from "src/app/shared/models/filtro-padrao/filtroPadrao";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent extends BaseComponent implements OnInit {
  public page = 5;

  public get Psicologo() { return (this.atribuicoesLogin == undefined && this.userLogado?.flPsicologo); }
  public get CoordenadorPsicologo() { return this.userLogado?.DadosUsuario?.filter(s => s.CodigoPerfil == 1632).length };
  public currentDate: string;
  public mesCalendario: number;
  public anoCalendario: number;
  public daysInMonth: { date: Date, isWeekend: boolean, isHoliday: boolean }[] = [];
  public qtdeCaracteresDescricao: number = 0;
  public showModalAdicionarChamado = false;
  public lstDiretorias: Diretoria[] = [];
  public lstEscolas: Escola[] = [];

  constructor(
    private config: NgSelectConfig,
    private chamadoService: ChamadoService,
    private encaminhamento: EncaminhamentoService,
    private relatorioService: RelatorioService,
    private naoOcorrenciaService: NaoOcorrenciaService
  ) {
    super();
  }

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  public showModal = false;
  public submittedModal = false;
  public tituloModal = '';
  public descricaoExclusao = '';
  public descricaoNaoOcorrencia = '';
  public dataOcorrenciaNaoOcorrencia = new Date();
  public chamadoARemover = 0;

  // FILTROS
  public gravidades = [{ id: 1, name: 'Amarelo' }, { id: 2, name: 'Vermelho' }];
  public lstChamados: ListarChamados[] = [];
  public lstAllChamados: ListarChamados[] = [];
  public alunosRecorrentes: AlunosRecorrentes[] = [];
  public listaDias: ListarCalendario[] = [];

  public formHomeNaoOcorrencia!: FormGroup;

  public get formNaoOco() { return this.formHomeNaoOcorrencia.controls; }

  // MODAL
  public submitted = false;
  public showPesquisa = false;
  public showModalExcluirChamado = false;
  public showModalCalendarioOcorrencia = false;
  public showModalCadastraNaoOcorrencia = false;
  public codigoChamadoSelected: number;
  public codigoEnvolvidoSelected: number;
  public formModal!: FormGroup;
  public get modalForm() { return this.formModal.controls; }

  //MODAL ENCERRAMENTO CHAMADO ENVOLVIDO
  public ShowModalEncerrarChamado = false;
  public descricaoEncerrarChamado = "";
  public chamadoEncerrarChamado = 0;

  //MODAL CONCLUIR CHAMADO
  public chamadoSeleccionado: ListarChamados;
  public item: ListarChamados;
  public showModalConcluirChamado = false;
  public descricaoConcluir = "";
  public chamadoAConcluir = 0;
  public chamadoHistorico: ListarChamados;

  public async ngOnInit() {


    this.formHomeNaoOcorrencia = new FormGroup({
      dsChamado: new FormControl(null)
    });

    var DateObj = new Date();
    this.anoCalendario = DateObj.getFullYear();
    this.mesCalendario = DateObj.getMonth();

  }

  public async generateDaysInMonth() {
    registerLocaleData(myLocalePt);

    const daysInMonth = new Date(this.anoCalendario, this.mesCalendario + 1, 0).getDate();
    const parametros = this.SetParametrosCalendario(this.anoCalendario, this.mesCalendario + 1);

    this.spinnerService.show();
    if(parametros.codigoDiretoria == null || parametros.codigoEscola == null){
      this.toastr.warning("Diretoria e Escola são obrigatórios");
      this.spinnerService.hide();
      return;
    }

    var response = await lastValueFrom(this.relatorioService.getCalendarioOcorrencias(parametros))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.listaDias = response.data;
    this.daysInMonth = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(this.anoCalendario, this.mesCalendario, i);
      const isWeekend = day.getDay() === 0 || day.getDay() === 6;
      const isHoliday = this.isHoliday(day);
      this.daysInMonth.push({ date: day, isWeekend, isHoliday });
    }

    this.spinnerService.hide();
  }


  public async goToPreviousMonth() {
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() - 1);

    if (this.mesCalendario == 0) {
      this.anoCalendario -= 1;
      this.mesCalendario = 11;
    } else
      this.mesCalendario -= 1;

    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
    await this.generateDaysInMonth();
  }

  public async goToCurrentMonth() {
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() + 1);

    if (this.mesCalendario == 11) {
      this.anoCalendario += 1;
      this.mesCalendario = 0;
    } else
      this.mesCalendario += 1;

    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
    await this.generateDaysInMonth();
  }

  public isCurrentDay(day: Date): boolean {
    const today = new Date();
    return day.getFullYear() === today.getFullYear() &&
      day.getMonth() === today.getMonth() &&
      day.getDate() === today.getDate();
  }

  public isHoliday(day: Date): boolean {
    // Implemente a lógica para verificar se o dia é um feriado
    // Retorne true se for um feriado, caso contrário, retorne false
    // Por exemplo:
    // return (day.getDate() === 25 && day.getMonth() === 11); // Natal (25 de dezembro)
    return false; // Por enquanto, não consideramos nenhum feriado
  }

  public async onDateChange(event: any) {
    await this.generateDaysInMonth();
  }

  public formatarMes(date: string): string {
    const mes = new Date(date);
    return formatDate(mes, 'LLLL', 'pt');
  }

  public formatarDiaSemana(date: Date): string {
    return formatDate(date, 'EEEE', 'pt-br');
  }






  public async getDiretorias() {
    this.spinnerService.show();

    const response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.lstDiretorias = response.data;
    this.spinnerService.hide();
  }

  public async getEscolas(diretoriaLogada: boolean = false) {
    const codigoDE = this.filtros.codigoDiretoria;
    if (codigoDE != null) {
      this.spinnerService.show();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.convivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.lstEscolas = response.data;
      this.spinnerService.hide();
    }
  }

  public async getCalendario() {
    this.mesCalendario = new Date().getMonth();
    this.anoCalendario = new Date().getFullYear();
    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
    await this.generateDaysInMonth();
  }

  public zerarTurma() {
    this.turmas = [];
    // this.formHome.get('codigoTurma')?.patchValue(null);
  }

  public async PesquisarChamados() {

    this.submitted = true;
    this.spinnerService.show();

    this.showPesquisa = true;
    const parametros = this.SetParametrosChamados();
    if(parametros.codigoDiretoria == null || parametros.codigoEscola == null){
      this.toastr.warning("Diretoria e Escola são obrigatórios");
      this.spinnerService.hide();
      return;
    }
    const result = await lastValueFrom(this.chamadoService.getChamadosEnvolvidos(parametros))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.lstChamados = result.data;

    if(this.CoordenadorPsicologo)
      this.lstChamados = this.lstChamados.filter(s =>s.aluno?.statusEnvolvido === 1);

    this.lstAllChamados = result.data;
    if (this.lstChamados.length == 0) {
      this.alunosRecorrentes = []; // Zerar Alunos Recorrentes
      this.toastr.warning("Não existem chamados para essa pesquisa");
      this.spinnerService.hide();
      return;
    }

    await this.generateDaysInMonth();

    await this.getAlunosRecorrentes(parametros);
  }

  public async getAlunosRecorrentes(parametros: ListarChamados) {
    this.spinnerService.show();

    const result = await lastValueFrom(await this.chamadoService.getAlunosRecorrentes(parametros))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.alunosRecorrentes = result.data;
    this.spinnerService.hide();
  }

  public SetParametrosChamados(): ListarChamados {
    const chamado = new ListarChamados();
    chamado.codigoDiretoria = this.filtros.codigoDiretoria;
    chamado.codigoEscola = this.filtros.codigoEscola;
    chamado.codigoTurno = this.filtros.codigoTurno;
    chamado.codigoTurma = this.filtros.codigoTurma;
    chamado.codigoGravidade = this.filtros.codigoGravidade;
    chamado.ordenacaoCrescente = false;

    return chamado;
  }

  public SetParametrosCalendario(ano: number, mes: number): Consolidado {
    const parametros = new Consolidado();
    parametros.codigoDiretoria = this.filtros.codigoDiretoria;
    parametros.codigoEscola = this.filtros.codigoEscola;
    parametros.ano = ano;
    parametros.mes = mes;
    return parametros;
  }

  public setParametrosEventoNotificacao(): ListarEncaminhados {
    const chamado = new ListarEncaminhados();
    if (this.userLogado != null && this.userLogado.codigoDiretoria != null)
      chamado.codigoDiretoria = this.userLogado.codigoDiretoria;
    if (this.userLogado != null && this.userLogado.codigoEscola != null)
      chamado.codigoEscola = this.userLogado.codigoEscola;
    return chamado;
  }

  public OnInserirOcorrenciaNaoOcorrencia(DataOcorrencia: Date, EstatusDia: Number) {
    var dataOco = formatDate(DataOcorrencia, 'yyyy-MM-dd', 'en_US');
    var hoje = formatDate(new Date, 'yyyy-MM-dd', 'en_US');

    let dateOco = new Date();
    // dateOco.setDate(dateOco.getDate() - 7);
    // var hojeMenos7 = formatDate(dateOco,'yyyy-MM-dd','en_US');
    // if(dataOco  < hojeMenos7)
    // {
    //   this.toastr.error('A data da ocorrência deve constar em até 7 dias depois do acontecimento.');
    //   this.spinnerService.hide();
    //   return;
    // }

    if (dataOco > hoje || !(EstatusDia == 4 || EstatusDia == 1))
      this.toastr.warning("Data não valida para uma ação");
    else {
      this.showModalCalendarioOcorrencia = true;
      this.dataOcorrenciaNaoOcorrencia = DataOcorrencia;
    }
  }

  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public FecharModalCalendario() {
    this.showModalCalendarioOcorrencia = false;
    this.descricaoNaoOcorrencia = "";
  }

  public FecharModalCadastroNaoOcorrencia() {
    this.showModalCadastraNaoOcorrencia = false;
  }

  public async InserirRegistroNaoOcorrencia() {
    var descricao = this.formHomeNaoOcorrencia.get('dsChamado')!.value;
    if (descricao == null)
      descricao = 'Nao houve chamados para o periodo selecionado';

    await this.getDiretorias();
    await this.getEscolas();

    const dir = this.lstDiretorias.filter(r => r.codigoDiretoria == this.filtros.codigoDiretoria)[0];
    const esc = this.lstEscolas.filter(r => r.codigoEscola == this.filtros.codigoEscola)[0];


    console.log(this.filtros.codigoDiretoria, dir);
    console.log(this.filtros.codigoEscola, esc);

    const objetoChamado: NaoOcorrencia = {
      cdGravidade: 0,
      cdCategoria: 13,
      cdSubCategoria: 42,
      dtNaoOcorrencia: formatDate(this.dataOcorrenciaNaoOcorrencia, 'yyyy-MM-dd', 'en_US'),
      cdDiretoria: dir.codigoDiretoria,
      nmDiretoria: dir.nomeDiretoria,
      cdEscola: esc.codigoEscola,
      nmEscola: esc.nomeEscolaSemCodigo,
      dsNaoOcorrencia: descricao,
      cdTurno: 0,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      cdNaoOcorrencia: 0
    };

    const result = await lastValueFrom(await this.naoOcorrenciaService.setCadastrarNaoOcorrencia(objetoChamado))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Chamado cadastrado com sucesso.');
    this.formNaoOco.dsChamado.setValue(null);

    setTimeout(() => {
      this.submitted = false;
      document.getElementById("btnLimpar")?.click();
    }, 2000);

    this.showModalCadastraNaoOcorrencia = false;
    this.showModalCalendarioOcorrencia = false;
    await this.generateDaysInMonth();
  }

  public InserirOcorrencia() {
    var dt = this.dataOcorrenciaNaoOcorrencia;
    this.router.navigate(['/cadastrar-chamados/', dt]);
  }

  public InserirNaoOcorrencia() {
    this.showModalCadastraNaoOcorrencia = true;
    this.descricaoNaoOcorrencia = "";
  }

  public AbrirModal(codigoChamado: number, codigoEnvolvido: number) {
    this.codigoChamadoSelected = codigoChamado;
    this.codigoEnvolvidoSelected = codigoEnvolvido;
    this.submittedModal = false;
    this.formModal = new FormGroup({
    });
    this.showModal = true;
    this.tituloModal = 'Encaminhamentos';
  }

  public FecharModal() {
    this.showModal = false;
  }

  public async EncaminhamentoPsicologo() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado ao Psicólogo.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamento(objetoHistorico))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado ao Psicologo com Sucesso.');
    this.FecharModal();
    this.PesquisarChamados();

  }

  public async EncaminhamentoPolicia() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Rede protetiva.',
      statusChamado: 2,//Encaminhado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoPolicia(objetoHistorico))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado a Rede protetiva.');
    this.FecharModal();
    this.PesquisarChamados();

  }

  public async EncaminhamentoSaude() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.codigoChamadoSelected,
      codigoEnvolvido: this.codigoEnvolvidoSelected,
      descricaoHistorico: 'Aluno encaminhado a Saúde.',
      statusChamado: 3,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    var response = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoSaude(objetoHistorico))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Aluno Encaminhado a Saúde com Sucesso.');
    this.FecharModal();
    this.PesquisarChamados();
  }

  public Proximamente() {
    this.toastr.warning("Esta funcionalidade será implementada futuramente");
  }

  public OnVisualizarChamado(id: number) {
    this.router.navigate(['/detalhe/', id]);
  }

  public FormatarDescricaoOcr(dsOcorrencia: string): string {
    if (dsOcorrencia != null) {

      if (dsOcorrencia.length > 80)
        return dsOcorrencia.substring(0, 80) + ' ...';
      else
        return dsOcorrencia;
    }

    return '--';
  }

  public getStatusEnvolvido(status: number | undefined): string | undefined {
    if (status != undefined) {
      const statusViagem = new Map<number, string>([
        [0, 'Não Analisado'],
        [1, 'Encaminhado ao Psicólogo'],
        [2, 'Encaminhado a Rede de Proteção'],
        [3, 'Encaminhado ao Hospital'],
        [4, 'Psicólogo Agendado'],
        [5, 'Atendimento Realizado'],
        [6, 'Concluído'],
      ]);

      return statusViagem.get(status);
    }

    return 'Não Analisado';
  }

  public getCorStatusEnvolvido(status: number | undefined): string | undefined {
    if (status != undefined) {
      const statusViagem = new Map<number, string>([
        [0, 'aberto'],
        [1, 'encaminhado'],
        [2, 'encaminhado'],
        [3, 'encaminhado'],
        [4, 'agendado'],
        [5, 'atendido'],
        [6, 'encerrado'],
      ]);

      return statusViagem.get(status);
    }

    return 'aberto';
  }

  public FiltraChamadosAtuais(event: any) {
    if (event != undefined) {
      this.lstChamados = this.lstAllChamados.filter(item => item.codigoGravidade === event.id);
    } else {
      this.lstChamados = this.lstAllChamados;
    }
  }

  public OnChangeCountLetters() {
    const descricao = this.formHomeNaoOcorrencia.get('dsChamado')!.value;
    if (descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }

  public EventTresPontos(event: string) {

    if (event === "Pesquisar") {
      this.PesquisarChamados();
    }

  }

  public FiltrarChamadoId(event: any) {
    if (event.target?.value) {
      this.lstChamados = this.lstAllChamados.filter(item => item.codigoChamado.toString().includes(event.target.value));
    }
    else
      this.lstChamados = this.lstAllChamados;
  }


  async listeningFiltroEmitter(eventFilter:FiltroPadrao){

      this.setFilters(eventFilter);
      await this.PesquisarChamados();
      await this.getCalendario();
  }

  setFilters(event:FiltroPadrao){
    this.filtros = event;


  }


}

