export const environment = {
  production: false,
  baseServerUrl : "https://homologacaosedintegracoes.educacao.sp.gov.br/conviva/",
  baseFrontUrl : "https://homologacaoconviva.educacao.sp.gov.br/",
  baseSedUrl: "https://homologacaosedintegracoes.educacao.sp.gov.br/credenciais/",
  hubUrl:"https://see-h-apiconviva.azurewebsites.net/",
  clientId: "seduc.conviva-portal",
  Apim: "56be8c628f0b4474b691ab314b90a800",
  ApimConviva: "722fa3cef8c943ba945f005169b04322",
  IdpSP: "https://rhsso.idp-hml.sp.gov.br/auth/realms/idpsp",
  IdpSpToken: "https://rhsso.idp-hml.sp.gov.br/auth/realms/idpsp/protocol/openid-connect/token",
  version: "1.1.0"
};
