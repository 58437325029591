import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";

@Injectable({
  providedIn: 'root'
})

export class ArquivoService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient
  )
  {}

  public setArquivoBase64(formData: FormData):Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Chamados.setFileBase64}`, formData)
                    .pipe(map((response: DefaultResponse) => response));
  }

  public getArquivoPorNome(nomeArquivo:string):Observable<Blob> {
    return this.http.get<Blob>(`${this.api.Chamados.GetArquivoChamado}?nomeArquivo=${nomeArquivo}`, {responseType:  'blob' as 'json'})
    .pipe(map((response: Blob) => response));
  }
}
