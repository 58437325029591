//Principais
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Location } from '@angular/common';
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination, Scrollbar, SwiperOptions, Virtual, Zoom } from 'swiper';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { Subscription, lastValueFrom } from "rxjs";
//Models
import { AtribuicaoLoginSed, UserLoginSed  } from "src/app/shared/models/sed/UserLogin";
import { ChamadoDetalhes } from 'src/app/shared/models/chamados/chamadoDetalhes';
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
//Services
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { HistoricoChamadoService } from "src/app/shared/services/historicoChamado.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
import { ConvivaApi } from "src/app/conviva-api";

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Controller
]);

@Component({
  selector: 'app-detalhe',
  templateUrl: './detalhe.component.html',
  styleUrls: ['./detalhe.component.scss'],
})

export class DetalheComponent implements OnInit {
  private api = ConvivaApi;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  private modalSubscription: Subscription;
  public qtdeCaracteresDescricao:number = 0;
  constructor(
    private spinnerService: NgxSpinnerService,
    private detalheService: DetalheService,
    private chamadoService: ChamadoService,
    private historicoChamadoService: HistoricoChamadoService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private authService: AuthService,
    private arquivoService: ArquivoService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService:ModalService,
    private httpClient:HttpClient,
    private location: Location)
  {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parametroId = params.get('id');
    });

    this.userLogado = this.authService.getUser();
    this.modalSubscription = this.modalService.modalClicked$.subscribe(() => {
     this.showModalArquivo = false;
    });

    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  @ViewChild('archivoInput', { static: false }) archivoInput: ElementRef;
  public chamado: ChamadoDetalhes;
  public envolvidos: Envolvido[] = [];
  public file: ArquivoAzure;
  public formVisualizar!: FormGroup;
  public formCadastrarHistorico!: FormGroup;
  public formAddInfoHistorico!: FormGroup;
  public parametroId: any;
  public showModalDetalheEnvolvido = false;
  public chamadoHistorico: ListarChamados;
  public itemChamado: ListarChamados;
  public lstHistoricos: HistoricoChamado[] = [];
  public historico: HistoricoChamado | undefined;
  public statusCriada:any;
  public statusEncaminhada:any;
  public statusFechada:any;
  public nomeSelected:any;
  public dsHistoricoChamado:string;
  public files: ArquivoAzure[] = [];
  public showModalArquivo : boolean;
  public showModalAddInfoHistorico : boolean;
  public get arquivoPDF(){
    return this.chamado.caminhoArquivo && this.chamado.caminhoArquivo[0].endsWith('.pdf')
  }

  goBack(): void {
    this.location.back();
  }

  // FORM CADASTRAR
  public get form() {
    return this.formVisualizar.controls;
  }

  public async ngOnInit() {
    this.formVisualizar = new FormGroup({});
    await this.PopularDados();
    this.formCadastrarHistorico = new FormGroup({
      dsHistoricoChamado: new FormControl(null),
    });
    this.formAddInfoHistorico = new FormGroup({
      newInfoHistoric:new FormControl(null,[Validators.maxLength(500),Validators.minLength(10)])
    });
  }

  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.detalheService.getChamadoDetalhePorId(this.parametroId))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.chamado = result.data;
    let arquivodata: ArquivoAzure = {
      nome: this.chamado.nomeArquivo!,
      ehBase64: true,
      caminhoAzure:this.chamado.caminhoArquivo![0],
      conteudo: this.chamado.conteudoBase64!
    };
    this.file = arquivodata;
    this.envolvidos = this.chamado.envolvidos
    let currentId = 1;
    this.envolvidos.forEach(pessoa => {
      pessoa.idTemporario = currentId++;
      pessoa.imagemTemporaria = pessoa.iniciais;//this.GerarIniciais(pessoa.nome!)
      pessoa.iniciais = pessoa.tipoPessoa == 3 ? "X" : pessoa.iniciais;
    });

    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  public configs: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      600: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      800: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1000: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };

  public GerarIniciais(nomeAluno: string){
    return nomeAluno
        ?.split(' ')
        .filter((parte) => parte.length > 3) // Filtra partes do nome com mais de 3 letras
        .map((parte) => parte.charAt(0)) // Mapeia para as iniciais
        .slice(0, 2) // Pega apenas as duas primeiras iniciais
        .join('');
  }

  public FecharModalArquivo(){
    this.showModalArquivo = false;
  }

  public AbrirModalArquivo(){
    this.showModalArquivo = true;
  }

  public async HistoricoChamado(cdChamado:number, cdEnvolvido?:number, nome?:string) {
    this.spinnerService.show();
    const chamadoEnvolvido =  new ListarChamados();
    chamadoEnvolvido.codigoChamado = cdChamado;
    chamadoEnvolvido.codigoEnvolvido = cdEnvolvido == null ? 0 : cdEnvolvido;
    this.nomeSelected = nome;
    const menu = document.getElementsByClassName("time-line")
    if(menu.length > 0){
      const teste = menu.item(0) as HTMLElement;
      teste.className = "sidebar-chamado";
    }
    this.itemChamado = chamadoEnvolvido;
    const result = await lastValueFrom(await this.chamadoService.getHistoricoEnvolvido(chamadoEnvolvido))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.lstHistoricos = result.data;
    this.chamadoHistorico = chamadoEnvolvido;
    this.setStatusHistorico();
    this.showModalDetalheEnvolvido = true;
    this.spinnerService.hide();
  }

  public setStatusHistorico()
  {
    let criados = this.lstHistoricos.filter(x => x.statusChamado == 0);
    if(criados.length > 0)
      this.statusCriada = criados[0].data;
    else
      this.statusCriada = null;
    let encaminhados = this.lstHistoricos.filter(x => x.statusChamado == 1);
    if(encaminhados.length > 0)
      this.statusEncaminhada = encaminhados[0].data;
    else
      this.statusEncaminhada = null;
    let fechados = this.lstHistoricos.filter(x => x.statusChamado == 5);
    if(fechados.length > 0)
      this.statusFechada = fechados[0].data;
    else
      this.statusFechada = null;
  }

  public async OnFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      for (const file of files) {
        formData.append(file.name, file);
      }
      const result = await lastValueFrom(await this.arquivoService.setArquivoBase64(formData))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.files = result.data;
      this.spinnerService.hide();
    }
  }

  public limpiarInputArchivo() {
    this.archivoInput.nativeElement.value = null;
  }

  public async onSalvarHistorico() {
    this.spinnerService.show();
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: this.formCadastrarHistorico.get('dsHistoricoChamado')?.value,
      statusChamado: 0,
      nomeArquivo: this.files.length == 0 ? "" : this.files[0].nome,
      caminhoArquivo: this.files.length == 0 ? "" : this.files[0].caminhoAzure,
      codigoPerfil: this.userLogado?.flPsicologo ? this.userLogado.codigoPerfil : this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.userLogado?.flPsicologo ? this.userLogado.nomePerfil : this.atribuicoesLogin.NomePerfil,
      conteudo: this.files.length == 0 ? "" : this.files[0].conteudo//,
    };
    if(objetoHistorico.nomeArquivo == "" && objetoHistorico.descricaoHistorico == undefined) {
        this.spinnerService.hide();
        this.toastr.error(
          'É obrigatório a descrição ou foto'
        );
        return;
    }
    const result = await lastValueFrom(await this.historicoChamadoService.setCadastrarHistoricoChamado(objetoHistorico))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.HistoricoChamado(this.itemChamado.codigoChamado, this.itemChamado.codigoEnvolvido, this.itemChamado.aluno?.nomeAluno);
    this.formCadastrarHistorico.get('dsHistoricoChamado')?.patchValue(null);
    this.limpiarInputArchivo();
    this.files = [];
    this.spinnerService.hide();
    this.toastr.success('Historico do chamado cadastrado com sucesso.');
  }

  downloadDocument(urlArquivo:string | undefined){
    if(urlArquivo){
      const nomeArquivo = this.extraiNomeArquivoDaUrlComGuid(urlArquivo);
      this.arquivoService.getArquivoPorNome(nomeArquivo).subscribe((data) => {
        saveAs(data,this.extraiNomeArquivoDaURLSemGuid(urlArquivo));
      });
    }
  }

  extraiNomeArquivoDaUrlComGuid(caminho:string){
    const splitName = caminho.split('/')
    return splitName[splitName.length-1];
  }
  extraiNomeArquivoDaURLSemGuid(url:string | undefined){
   if(!url)
    return '';
    let nomeArquivo = this.extraiNomeArquivoDaUrlComGuid(url);
    const nomeSeparadoPorTraco = nomeArquivo.split('-');
    return nomeSeparadoPorTraco[nomeSeparadoPorTraco.length - 1]
   }

   openModalAddInfo(cdHistorico:number | undefined){
    this.showModalAddInfoHistorico = true;
    if(cdHistorico != 0)
        this.historico = this.lstHistoricos.find(s=>s.codigoHistorico == cdHistorico );

   }

   closeModalAddInfo(){
    this.formAddInfoHistorico.get('newInfoHistoric')?.setValue(null);
    this.qtdeCaracteresDescricao = 0;
    this.showModalAddInfoHistorico = false;
   }


   async saveNewInfoOnHistoric(){
    this.spinnerService.show();
    const novaInfo = this.formAddInfoHistorico.get('newInfoHistoric')!.value;
    if(this.formAddInfoHistorico.invalid){
      this.toastr.error('O campo está vazio ou não cumpre o mínimo de caracteres.');
      this.spinnerService.hide();
      return;
    }
    const body = {
      cdHistorico:this.historico?.codigoHistorico,
      novaInformacao: novaInfo
    };


    const result = await lastValueFrom(await this.historicoChamadoService.SetInformacaoAdicional(body))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success(result.data.mensagem);

    this.closeModalAddInfo();
    await this.HistoricoChamado(this.historico!.codigoChamado,this.historico?.codigoEnvolvido);
    this.spinnerService.hide();
   }

   OnChangeCountLetters(){
    const descricao = this.formAddInfoHistorico.get('newInfoHistoric')!.value;
    if(descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }
}
