export class UserLoginSed {
  statusCode:number;
  cpf:string;
  codigoUsuario:number;
  login:string;
  email:string;
  nome:string;
  codigoPerfil:number;
  nomePerfil:string;
  NumeroComportamento:string;
  comportamento:string;
  codigoDiretoria?: number;
  codigoEscola?: number;
  perfis: PerfilLoginSed[];
  DadosUsuario: AtribuicaoLoginSed[];
  funcionalidades: number[];
  flPsicologo:boolean;
  codigoPolo:number;
  codigoPsicologo:number;
  currentPage?:number;

  public static MappingUsuarioConviva (userV2:AutorizaGovBr): UserLoginSed {

    var userLogado = new UserLoginSed();
    userLogado.statusCode = userV2.statusCode;
    userLogado.perfis = userV2.DadosUsuario.Perfis.map((item:PerfilLoginSedV2)=>{
      let perfil:PerfilLoginSed = new PerfilLoginSed();
      perfil.codigoComportamento = item.CodigoComportamento;
      perfil.codigoPerfil = item.CodigoPerfil;
      perfil.descricaoPerfil = item.NomePerfil;
      perfil.descricaoComportamento = item.NomeComportamento;
      return perfil;
      })
    var dadosUsuario = new AtribuicaoLoginSed();
    dadosUsuario.CodigoUsuario = userV2.DadosUsuario.CodigoUsuario;
    dadosUsuario.Login = userV2.DadosUsuario.Login;
    dadosUsuario.Cpf = userV2.DadosUsuario.CPF;
    dadosUsuario.Nome =  userV2.DadosUsuario.Nome;
    dadosUsuario.Email = userV2.DadosUsuario.Email;
    userLogado.DadosUsuario =[dadosUsuario];

    userLogado.codigoUsuario = userV2.DadosUsuario.CodigoUsuario;
    userLogado.cpf = this.formatCPF(userV2.DadosUsuario.CPF);
    userLogado.email = userV2.DadosUsuario.Email;
    userLogado.login = userV2.DadosUsuario.Login;
    userLogado.nome = userV2.DadosUsuario.Nome;


    return userLogado;
  }
  private static formatCPF(cpf: number): string {
    let cpfString = cpf.toString();

    if (cpfString.length === 11) {
        return cpfString;
    } else {
        // Adiciona zeros à esquerda até que a string tenha 11 caracteres
        while (cpfString.length < 11) {
            cpfString = '0' + cpfString;
        }
        return cpfString;
    }
    }
}

export class PerfilLoginSed {
  codigoPerfil:number;
  descricaoPerfil:string;
  codigoComportamento:number;
  descricaoComportamento:string;
}

export class AtribuicaoLoginSed {
  CodigoUsuario:number;
  Login:string;
  Cpf:number;
  Nome: string;
  CodigoPerfil:number;
  NomePerfil: string;
  NumeroComportamento: number;
  Comportamento: string;
  CodigoTurma: number;
  CodigoDiretoria: number;
  NomeDiretoria: string;
  CodigoEscola: number;
  NomeEscola: string;
  DataInclusao: Date;
  CodigoPolo: number;
  Email:string;

  public MappingAtribuicoesConviva(atr2:Atribuicao):AtribuicaoLoginSed{
    let atr:AtribuicaoLoginSed = new AtribuicaoLoginSed();

    return atr;
  }

}


// V2
export class AutorizaGovBr{
  statusCode:number;
  statusRetorno:string;
  DadosUsuario:DadosUsuario;
}

export class DadosUsuario{
  CodigoUsuario:number;
  Login:string;
  Nome:string;
  CPF:number;
  Email:string;
  Perfis:PerfilLoginSedV2[]
}

export class PerfilLoginSedV2{
  CodigoPerfil:number;
  NomePerfil:string;
  CodigoComportamento:number;
  NomeComportamento:string;
}

export class AtribuicoesPorUsuario{
    statusCode:number;
    statusRetorno:string;
    DadosUsuario:Atribuicao[]
}
export class Atribuicao{
    CodigoTurma: number;
    NomeTurma: string;
    CodigoEscola: number;
    NomeEscola: string;
    CodigoDiretoria: number;
    NomeDiretoria: string;
}

