import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { PocAtividade } from 'src/app/shared/models/professor/DetalheAtividadePOC';
import { TurmasPOC } from 'src/app/shared/models/professor/TurmasAprovacaoPOC';
import { UserLoginSed, AtribuicaoLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ConcluirService } from 'src/app/shared/services/concluir.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
  selector: 'app-vizualizar-atividade-poc',
  templateUrl: './vizualizar-atividade-poc.html',
  styleUrls: ['./vizualizar-atividade-poc.scss']
})
export class VizualizarAtividadePocComponent implements OnInit {

  public dtOptions: any = {};
  public parametroId: any;
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();

  constructor(
    private spinnerService: NgxSpinnerService,
    private detalheService: DetalheService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private locale: string,
    private envolvidoService: EnvolvidoService,
    private concluirService: ConcluirService,
    private psicologoService: PsicologoService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userLogado = this.authService.getUser();

      if(this.userLogado != null){
        this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];

        this.route.paramMap.subscribe((params) => {
          this.parametroId = Number(params.get('id')) ?? 0;
        });


      }
  }

//Listas da tela
public atividade: PocAtividade = new PocAtividade();
public lstEnvolvidos: Envolvido[] = [];
public lstTurmas: TurmasPOC[] = [];

 // FORM CADASTRAR
public formCadastrar!: FormGroup;

public get form() {
  return this.formCadastrar.controls;
}

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3]
          }
        }
      ],
    };

    this.formCadastrar = new FormGroup({
      dsAprovar: new FormControl('')
    });
    await this.PopularDados();
  }

  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(this.detalheService.getAtividadeDetalhePOCPorId(this.parametroId))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.atividade = result.data

    if(this.atividade.tP_ATIVIDADE_REALIZADA == 1){
      var envolvido = await lastValueFrom(
        this.envolvidoService.GetEnvolvidosPorAtividadePOC(this.atividade.cD_ATIVIDADE_POC));
        this.lstEnvolvidos = envolvido.data;
    }
    if(this.atividade.tP_ATIVIDADE_REALIZADA == 2){
      var turmas = await lastValueFrom(this.psicologoService.getTurmasAprovacaoPOC(this.atividade.cD_ATIVIDADE_POC));
      this.lstTurmas = turmas.data;
    }

       setTimeout(() => {
         this.spinnerService.hide();
       }, 3000);
  }

  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }

  public abrirEmNovaAba(caminho:string): void {
    if (this.atividade && caminho) {
      window.open(caminho, '_blank');
    }
  }

  getShortenedName(name: string): string {
    if (!name || name.length <= 19) {
      return name;
    }

    const firstPart = name.slice(0, 15);
    const lastPart = name.slice(-4);
    return `${firstPart}${lastPart}`;
  }

}
