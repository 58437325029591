<div class="btn-acoes">
  <label for="card-feed-{{ this.Index + 1 }}">
    <a class="btn-view-card"></a>
  </label>

  <input type="checkbox" checked class="card-opcoes-card" id="card-opcoes-card-{{ this.Index + 1 }}"/>

  <label href="javascript:void(0)" for="card-opcoes-card-{{ this.Index + 1 }}">
    <a class="btn-opcoes-card"></a>
  </label>

  <div class="opcoes-card caixa-opcoes">

    <a *ngIf="this.userLogado?.funcionalidades?.includes(4) && this.Chamado.status === 0"
        href="javascript:void(0)"
        (click)="OnExcluirChamado(this.Chamado.codigoChamado)">
      Excluir
    </a>

    <a *ngIf="this.userLogado?.funcionalidades?.includes(3) && this.Chamado.status === 0 && this.Chamado.numeroBOSSP == null"
        href="javascript:void(0)"
        (click)="OnEditarChamado(this.Chamado.codigoChamado)">
      Alterar
    </a>

    <a *ngIf="!this.IsVisualizarChamado"
       href="javascript:void(0)"
       (click)="OnVisualizarChamado(this.Chamado.codigoChamado)">
      Visualizar
    </a>

    <a *ngIf="podeConcluirChamado(this.Chamado)"
        href="javascript:void(0)"
        (click)="AbrirModalConcluirChamado(this.Chamado.codigoChamado)"
    >
      Concluir
    </a>

    <a *ngIf="this.VerificaStatusEnvolvido() && (this.userLogado?.funcionalidades?.includes(5)) && this.TemEnvolvido"
        href="javascript:void(0)"
        (click)="AbrirModalEncerrarChamado(this.Chamado.codigoChamado, this.Chamado.codigoEnvolvido)">
      Encerra Chamado do Envolvido
    </a>

    <a *ngIf="((Chamado.aluno?.statusEnvolvido == 0) &&
               (this.userLogado?.funcionalidades?.includes(6) ||
                this.userLogado?.funcionalidades?.includes(7) ||
                this.userLogado?.funcionalidades?.includes(8))) && this.TemEnvolvido"
        href="javascript:void(0)"
        (click)="AbrirModalEncaminhamentos(this.Chamado.codigoChamado, this.Chamado.codigoEnvolvido)">
      Encaminhamento
    </a>

  </div>
</div>

<!-- Modal de Exclusão -->
<app-modal *ngIf="showModalExcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span>{{this.descricaoExclusao}}</span>
    </div>
    <div class="container-btns">
      <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
      <input type="button" (click)="ExcluirChamado(this.chamadoARemover)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>

<!-- Modal de Encerrar Chamado -->
<app-modal *ngIf="ShowModalEncerrarChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Encerrar o Chamado do Envolvido</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span >{{ this.descricaoConcluir }}</span>
    </div>
    <div class="container-btns">
      <input type="button" (click)="FecharModalEncerrarChamado()" class="btn_outline" value="Não"/>
      <input type="button" (click)="EncerrarChamado()" class="btn_primary" value="Sim"/>
    </div>
  </div>
</app-modal>

<!-- Modal de Encaminhamentos -->
<app-modal *ngIf="showModal">
  <form [formGroup]="formModal">
    <div>
      <div>
        <h1 class="titulo-page">{{ this.tituloModal }}</h1>
      </div>
      <br />

      <div>
        <div class="container-btns container-btns-centrar grupo-filter">
          <div *ngIf="this.userLogado?.funcionalidades?.includes(6)">
            <button
              type="button"
              (click)="EncaminhamentoPsicologo()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Psicólogo
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(7)">
            <button
              type="button"
              (click)="EncaminhamentoPolicia()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-police.svg" alt="" />
              &nbsp; Rede Protetiva
            </button>
          </div>
          <br>
          <div *ngIf="this.userLogado?.funcionalidades?.includes(8)">
            <button
              type="button"
              (click)="EncaminhamentoSaude()"
              class="btn-action">
              <img src="../../../assets/img/icons/btn-hosp.svg" alt="" />
              &nbsp; Rede Hospitalar
            </button>
          </div>
          <br>
          <div>
            <button type="button" (click)="FecharModalEncaminhamentos()" class="btn-action">
              &nbsp; Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-modal>


<app-modal *ngIf="showModalConcluirChamado">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Concluir Chamado</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span >{{ this.descricaoConcluir }}</span>

    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalConcluir()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ConcluirChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>
