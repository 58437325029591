<form [formGroup]="formDashboard" (submit)="PesquisarChamados()">
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro" >
    <div class="container">
      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
          (change)="getEscolas()"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="getTurnos()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
      </div>

      <div class="grupo-filter">
        <label for="select-categoria">Categoria</label>
        <ng-select
          [items]="categorias"
          bindLabel="descricaoSubCategoria"
          bindValue="codigoSubCategoria"
          groupBy="descricaoCategoria"
          placeholder="Selecione uma Sub Categoria"
          name="codigoSubCategoria"
          formControlName="codigoSubCategoria"
          [ngClass]="{
            'is-invalid': submitted && form.codigoSubCategoria.errors
          }"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoCategoria || "Unnamed group" }}
          </ng-template>
        </ng-select>

        <div *ngIf="submitted && form.codigoSubCategoria.errors" class="alert">
          <div *ngIf="form.codigoSubCategoria.errors.required">
            * Sub Categoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turno</b></p>
        <ng-select
          [items]="turnos"
          bindLabel="nomeTurno"
          bindValue="codigoTurno"
          placeholder="Selecione um Turno"
          formControlName="codigoTurno"
          name="codigoTurno"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
          (change)="getTurmas()"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
          <div *ngIf="form.codigoTurno.errors.required">
            * Turno é obrigatório
          </div>
        </div>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Chamados"
          title="Pesquisar Chamados"
        />
      </button>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Painel Geral</h2>

      <div class="periodo">
        <p><b>Pesquisar por período:</b></p>
        <div class="periodo-input">
          <input
          type="date"
          name="dataInicial"
          min="{{minDate}}"
          max="{{maxDate}}"
          (blur)="validaAno(this.form.dataInicio)"
          formControlName="dataInicio"
          />
          &nbsp;-&nbsp;
          <input
          type="date"
          name="dataFinal"
          min="{{minDate}}"
          max="{{maxDate}}"
          (blur)="validaAno(this.form.dataFim)"
          formControlName="dataFim"
          />
        </div>
      </div>
    </div>
  </section>

  <!-- RESULTADOS -->
  <section class="conteudo-principal">

      <div class="container">

        <div class="conteudo-perfil" *ngIf="this.submitted && this.showPesquisa">

          <div class="conteudo-casos">
            <a
              href="javascript:void(0)"
              class="box-casos todos"
              (click)="PesquisarChamados()">
              <p class="number-casos">{{this.totalChamados}}</p>
              <p class="legenda-casos">Total de Chamados</p>
            </a>
            <a
              href="javascript:void(0)"
              class="box-casos abertos"
              (click)="PesquisarChamados(0)">
              <p class="number-casos">{{this.totalChamadosAbertos}}</p>
              <p class="legenda-casos">Chamados em Aberto</p>
            </a>
            <a
              href="javascript:void(0)"
              class="box-casos encerrados"
              (click)="PesquisarChamados(6)">
              <p class="number-casos">{{this.totalChamadosEncerrados}}</p>
              <p class="legenda-casos">Chamados Encerrados</p>
            </a>
            <a
             href="javascript:void(0)"
             class="box-casos criados"
             (click)="PesquisarChamados(1)">
              <p class="number-casos">{{this.totalChamadosEncaminhados}}</p>
              <p class="legenda-casos">Chamados Encaminhados</p>
            </a>
          </div>

          <h3 class="sub-titulo-dash">
            Listagem de Chamados
          </h3>

          <app-tabulator-table [tableData]="lstChamados" [columnNames]="colunasGrid" ></app-tabulator-table>

        </div>

      </div>

  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
