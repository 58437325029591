import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { Perfil } from 'src/app/shared/enums/Perfis/Perfis';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { HistoricoChamado } from 'src/app/shared/models/chamados/historico';
import { AprovacaoDiretorAtividadePOC, AprovacaoPECAtividadePOC } from 'src/app/shared/models/professor/AprovacaoAtividadePOC';
import { PocAtividade } from 'src/app/shared/models/professor/DetalheAtividadePOC';
import { TurmasPOC } from 'src/app/shared/models/professor/TurmasAprovacaoPOC';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ConcluirService } from 'src/app/shared/services/concluir.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
  selector: 'app-aprovacao-visitas',
  templateUrl: './aprovacao-visitas.component.html',
  styleUrls: ['./aprovacao-visitas.component.scss']
})

export class PageAprocacaoPocVisitasComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  public isChecked = false;
  public textareaRequired: boolean = false;
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized: boolean = false;
  public parametroId: any;
  public selectedCheckbox: boolean = false;
  public checkbox1: boolean = false;
  public checkbox2: boolean = false;
  public checkbox3: boolean = false;

  public disableInputsDiretor: boolean;
  public disableInputsPEC: boolean;



  public chamadoEncerrar: number | null = null;
  public tipoAprovacao: boolean = true;

  constructor(
    private spinnerService: NgxSpinnerService,
    private detalheService: DetalheService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private locale: string,
    private envolvidoService: EnvolvidoService,
    private concluirService: ConcluirService,
    private psicologoService: PsicologoService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userLogado = this.authService.getUser();

    if (this.userLogado != null) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];

      this.route.paramMap.subscribe((params) => {
        this.parametroId = Number(params.get('id')) ?? 0;
      });


    }
  }
  //Forms
  public formVisualizar!: FormGroup;
  public isButtonVisible: boolean = true;
  public submitted: boolean;

  //Modal
  public showModalConfirmacao: boolean;

  //Count text areas
  textAreaDiretorCount:number = 0;
  textAreaPECCount:number = 0;

  //Listas da tela
  public atividade: PocAtividade = new PocAtividade();
  public lstEnvolvidos: Envolvido[] = [];
  public lstTurmas: TurmasPOC[] = [];

  //Estrela component
  public ratingDiretor: number ;
  public ratingPEC: number ;
  public checkboxNumberDiretor: number;
  public validCheckBoxNumberDiretor() {
    this.checkboxNumberDiretor = this.form.CheckBoxDiretor1.value ? 1 : this.form.CheckBoxDiretor2.value ? 2 : 3;
  }
  public checkboxNumberPEC: number;
  public validCheckBoxNumberPEC() {
    this.checkboxNumberPEC = this.checkbox1 ? 1 : this.checkbox2 ? 2 : 3;
  }


  // FORM CADASTRAR
  public formCadastrar!: FormGroup;

  public get form() {
    return this.formCadastrar.controls;
  }

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy: true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 linhas', '25 linhas', '50 linhas', 'Tudo']
      ],
      buttons: [
        'pageLength',
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3]
          }
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: [0, 1, 2, 3]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3]
          }
        }
      ],
    };

    this.formCadastrar = new FormGroup({
      dsAprovar: new FormControl('', [Validators.maxLength(500)]),
      dsAprovarDiretor: new FormControl('', [Validators.maxLength(500)]),
      CheckBoxDiretor1: new FormControl(false),
      CheckBoxDiretor2: new FormControl(false),
      CheckBoxDiretor3: new FormControl(false),

    });

    this.handleCheckboxChanges();
    await this.PopularDados();
    this.setDadosAprovacao();
    this.disableInputsPerfis();
  }

  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(this.detalheService.getAtividadeDetalhePOCPorId(this.parametroId))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.atividade = result.data



    if (this.atividade.tP_ATIVIDADE_REALIZADA == 1) {
      var envolvido = await lastValueFrom(
        this.envolvidoService.GetEnvolvidosPorAtividadePOC(this.atividade.cD_ATIVIDADE_POC));
      this.lstEnvolvidos = envolvido.data;
    }
    if (this.atividade.tP_ATIVIDADE_REALIZADA == 2) {
      var turmas = await lastValueFrom(this.psicologoService.getTurmasAprovacaoPOC(this.atividade.cD_ATIVIDADE_POC));
      this.lstTurmas = turmas.data;
    }

    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }
  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }

  onChange1() {
    this.checkbox2 = false;
    this.checkbox3 = false;
    this.tipoAprovacao = true;
    this.validCheckBoxNumberPEC();
    this.ratingPEC = 0;
  }
  onChange2() {
    this.checkbox1 = false;
    this.checkbox3 = false;
    this.tipoAprovacao = true;
    this.validCheckBoxNumberPEC();
    this.ratingPEC = 0;
  }
  onChange3() {
    this.checkbox1 = false;
    this.checkbox2 = false;
    this.tipoAprovacao = false;

    this.validCheckBoxNumberPEC();
    this.ratingPEC = 0;
  }





  public AbreFechaModalConfirmacao(codigoChamado?: number) {
    if (codigoChamado !== undefined) {
      this.chamadoEncerrar = this.atividade.cD_CHAMADO!;
    }
    this.showModalConfirmacao = !this.showModalConfirmacao;
  }

  public async ConcluirChamado() {
    if (this.chamadoEncerrar !== null) {
      this.spinnerService.show();

      const objetoHistorico: HistoricoChamado = {
        codigoChamado: this.atividade.cD_CHAMADO!,
        codigoEnvolvido: this.atividade.cD_ENVOLVIDO,
        descricaoHistorico: 'Chamado deste envolvido foi encerrado pela atividade exercida pelo Professor POC.',
        statusChamado: 6,
        nomeArquivo: "",
        caminhoArquivo: "",
        conteudo: "",
        codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
        nomePerfil: this.atribuicoesLogin.NomePerfil
      };

      this.AbreFechaModalConfirmacao();

      this.spinnerService.show();

      const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividade(objetoHistorico))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });

      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.isButtonVisible = true;
      this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
      this.spinnerService.hide();
    }
  }

  public abrirEmNovaAba(caminho: string): void {
    if (this.atividade && caminho) {
      window.open(caminho, '_blank');
    }
  }

  public async AprovarAtividadeDiretor() {
    this.spinnerService.show();
    this.submitted = true;
    let situacaoAprovacaoDiretoria = this.getCheckboxNumber(this.form.CheckBoxDiretor1.value, this.form.CheckBoxDiretor2.value, this.form.CheckBoxDiretor3.value);

    const objetoAprovacao: AprovacaoDiretorAtividadePOC = {
      cD_USUARIO_APROVACAO_DIRETORIA: this.atribuicoesLogin.CodigoUsuario,
      cD_PERFIL_APROVACAO_DIRETORIA: this.atribuicoesLogin.CodigoPerfil,
      nM_PERFIL_APROVACAO_DIRETORIA: this.atribuicoesLogin.NomePerfil,
      oB_APROVACAO_DIRETORIA: this.form.dsAprovarDiretor.value,
      sT_APROVACAO_DIRETORIA: situacaoAprovacaoDiretoria,
      cD_ATIVIDADE_POC: this.atividade.cD_ATIVIDADE_POC,
      eSTRELAS_APROVACAO_DIRETORIA: this.ratingDiretor
    };

    if (this.atividade.sT_APROVACAO_DIRETORIA == 1) {
      this.toastr.error(`A atividade código ${this.atividade.cD_ATIVIDADE_POC} já foi aprovada`);
      this.spinnerService.hide();
      return;
    }

    if (!this.form.CheckBoxDiretor1.value
      && !this.form.CheckBoxDiretor2.value
      && !this.form.CheckBoxDiretor3.value) {
      this.toastr.error('Por favor, preencha alguma das opções avaliando a atividade do Professor POC');
      this.spinnerService.hide();
      return;
    }

    if (this.form.dsAprovarDiretor.value == "") {
      this.toastr.error('Por favor, responda a avaliação sobre a atividade do Professor POC');
      this.spinnerService.hide();
      return;
    }

    if(this.form.dsAprovarDiretor.value.length < 180 || this.form.dsAprovarDiretor.value.length > 500){
      this.toastr.error('A descrição deve conter no minimo 180 caracteres e no máximo 500');
      this.spinnerService.hide();
      return;
    }

    if(this.ratingDiretor == 0){
      this.toastr.warning('Por favor, avalie a atividade do Psicologo');
      this.spinnerService.hide();
      return;
    }


    var response = await lastValueFrom(this.psicologoService.aprovacaoDiretorAtividadePOC(objetoAprovacao))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    if (this.atividade.cD_CHAMADO != null) {
      await this.ConcluirChamadoAtividade();
    }
    this.toastr.success("Atividade aprovada com sucesso!");
    this.spinnerService.hide();
    this.router.navigate(['/dashboard-visitas-poc/']);
  }

  public async AprovarAtividadePEC() {
    this.spinnerService.show();
    this.submitted = true;
    let situacaoAprovacaoDiretoria = this.getCheckboxNumber(this.checkbox1, this.checkbox2, this.checkbox3);

    const objetoAprovacao: AprovacaoPECAtividadePOC = {
      cD_ATIVIDADE_POC: this.atividade.cD_ATIVIDADE_POC,
      cD_PERFIL_APROVACAO_PEC: this.userLogado!.codigoPerfil,
      cD_USUARIO_APROVACAO_PEC: this.userLogado!.codigoUsuario,
      eSTRELAS_APROVACAO_PEC: this.ratingPEC,
      nM_PERFIL_APROVACAO_PEC: this.userLogado!.nomePerfil,
      oB_APROVACAO_PEC: this.form.dsAprovar.value,
      //  dT_APROVACAO_PEC: new Date(),
      sT_APROVACAO_PEC: situacaoAprovacaoDiretoria
    };

    if (this.atividade.sT_APROVACAO_DIRETORIA == 1) {
      this.toastr.error(`A atividade código ${this.atividade.cD_ATIVIDADE_POC} já foi aprovada`);
      this.spinnerService.hide();
      return;
    }

    if (!this.checkbox1 && !this.checkbox2 && !this.checkbox3) {
      this.toastr.error('Por favor, preencha alguma das opções avaliando a atividade do Professor POC');
      this.spinnerService.hide();
      return;
    }

    if (this.form.dsAprovar.value == "") {
      this.toastr.error('Por favor, responda a avaliação sobre a atividade do Professor POC');
      this.spinnerService.hide();
      return;
    }

    if(this.form.dsAprovar.value.length < 180 || this.form.dsAprovar.value.length > 500){
      this.toastr.error('A descrição deve conter no minimo 180 caracteres e no máximo 500');
      this.spinnerService.hide();
      return;
    }

    if(this.ratingPEC == 0){
      this.toastr.warning('Por favor, avalie a atividade do Psicologo');
      this.spinnerService.hide();
      return;
    }



    var response = await lastValueFrom(this.psicologoService.aprovacaoPECAtividadePOC(objetoAprovacao))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    if (this.atividade.cD_CHAMADO) {
      await this.ConcluirChamadoAtividade();
    }

    this.toastr.success("Atividade aprovada com sucesso!");
    this.spinnerService.hide();
    this.router.navigate(['/dashboard-visitas-poc/']);
  }





  private getCheckboxNumber(value: boolean, value2: boolean, value3: boolean): number {
    if (value) {
      return 1;
    } else if (value2) {
      return 2;
    } else if (value3) {
      return 3;
    } else {
      return 0;
    }
  }




  getShortenedName(name: string): string {
    if (!name || name.length <= 19) {
      return name;
    }

    const firstPart = name.slice(0, 15);
    const lastPart = name.slice(-4);
    return `${firstPart}${lastPart}`;
  }

  public async ConcluirChamadoAtividade() {

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.atividade.cD_CHAMADO!,
      codigoEnvolvido: 0,
      descricaoHistorico: 'Chamado deste envolvido foi encerrado.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      CD_ATIVIDADE_POC: this.atividade.cD_ATIVIDADE_POC
    };

    const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividadePOC(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.isButtonVisible = true;
    this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
  }

  getRatingPEC(event: any) {
    this.ratingPEC = event;
  }

  getRatingDiretor(event: number) {
    this.ratingDiretor = event;
  }

  handleCheckboxChanges() {
    this.formCadastrar.get('CheckBoxDiretor1')?.valueChanges.subscribe((value) => {
      if (value) {
        this.formCadastrar.patchValue({ CheckBoxDiretor2: false, CheckBoxDiretor3: false }, { emitEvent: false });
        if (!this.disableInputsDiretor) {
          this.ratingDiretor = 0;
          this.validCheckBoxNumberDiretor();

        }
      }
    });

    this.formCadastrar.get('CheckBoxDiretor2')?.valueChanges.subscribe((value) => {
      if (value) {
        this.formCadastrar.patchValue({ CheckBoxDiretor1: false, CheckBoxDiretor3: false }, { emitEvent: false });
        if (!this.disableInputsDiretor) {
          this.ratingDiretor = 0;
          this.validCheckBoxNumberDiretor();
        }
      }
    });

    this.formCadastrar.get('CheckBoxDiretor3')?.valueChanges.subscribe((value) => {
      if (value) {
        this.formCadastrar.patchValue({ CheckBoxDiretor1: false, CheckBoxDiretor2: false }, { emitEvent: false });
        if (!this.disableInputsDiretor) {
          this.ratingDiretor = 0;
          this.validCheckBoxNumberDiretor();

        }
      }
    });

  }

  showTextAreaDiretor() {
    return this.form.CheckBoxDiretor1.value || this.form.CheckBoxDiretor2.value || this.form.CheckBoxDiretor3.value;
  }

  showTextAreaPEC() {
    return this.checkbox1 || this.checkbox2 || this.checkbox3;
  }

  disableInputsPerfis() {
    const perfilAtual = this.userLogado?.codigoPerfil;
    if ( perfilAtual != Perfil.GestorConviva) {
      if (perfilAtual != Perfil.DiretorEscolar) {
        this.onDisableInputsDiretor();
      }

      if(perfilAtual != Perfil.PEC){
        this.onDisableInputsPEC();
      }
    }

  }

  onDisableInputsPEC() {
    this.disableInputsPEC = true;
    this.form.dsAprovar.disable();
  }

  onDisableInputsDiretor() {
    this.disableInputsDiretor = true;
    this.form.dsAprovarDiretor.disable();
    this.form.CheckBoxDiretor1.disable();
    this.form.CheckBoxDiretor2.disable();
    this.form.CheckBoxDiretor3.disable();
  }

  setDadosAprovacao() {

    if (this.atividade.sT_APROVACAO_DIRETORIA) {
      const statusAprovacao = this.atividade.sT_APROVACAO_DIRETORIA;
      if (statusAprovacao == 1) {
        this.form.CheckBoxDiretor1.setValue(true);
      } else if (statusAprovacao == 2) {
        this.form.CheckBoxDiretor2.setValue(true);
      } else {
        this.form.CheckBoxDiretor3.setValue(true);
      }

      this.form.dsAprovarDiretor.setValue(this.atividade.oB_APROVACAO_DIRETORIA);
      this.ratingDiretor = this.atividade.estrelaS_AVALIACAO_DIRETORIA!;
      this.onDisableInputsDiretor();
    }

    if (this.atividade.sT_APROVACAO_PEC) {
      const statusAprovacao = this.atividade.sT_APROVACAO_PEC;
      if (statusAprovacao == 1) {
        this.checkbox1 = true;
      } else if (statusAprovacao == 2) {
        this.checkbox2 = true;
      } else {
        this.checkbox3 = true;
      }

      this.form.dsAprovar.setValue(this.atividade.oB_APROVACAO_PEC);
      this.ratingPEC = this.atividade.estrelaS_AVALIACAO_PEC!;
      this.onDisableInputsPEC();
    }

  }


  showAprovar() {
    return this.form.CheckBoxDiretor3.value == false;
  }

  onChangeTextAreaDiretor(){
    this.textAreaDiretorCount = this.form.dsAprovarDiretor.value.length;
  }
  onChangeTextAreaPEC(){
    this.textAreaPECCount = this.form.dsAprovar.value.length;
  }

}

