import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgSelectConfig } from "@ng-select/ng-select";
import { OAuthService } from "angular-oauth2-oidc";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { Perfil } from "src/app/shared/enums/Perfis/Perfis";
import { DefaultResponse } from "src/app/shared/models/DefaultResponse";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Atribuicao, AtribuicaoLoginSed, PerfilLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { PerfilService } from "src/app/shared/services/perfil.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";

@Component({
  selector:'modal-alterar-perfil',
  templateUrl: './modal-alterar-perfil.component.html',
  styleUrls: ['./modal-alterar-perfil.component.scss'],
})
export class ModalAlterarPerfil implements OnInit{

  public usuario: UserLoginSed;
  public usuarioLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  constructor(private spinnerService: NgxSpinnerService,
    private config: NgSelectConfig,
    private oauthService: OAuthService,
    private authService: AuthService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastrService:ToastrService,
    private router:Router,
    private perfilService: PerfilService,
    private escolaService:EscolaService,
    private psicologoService:PsicologoService
  ) {
    this.config.notFoundText = 'Não encontrado';
    this.usuarioLogado = this.authService.getUser();

    if(this.usuarioLogado != null && !this.usuarioLogado.flPsicologo){
      this.atribuicoesLogin = this.usuarioLogado?.DadosUsuario[0];
    }
  }

  public showModalAlterarPerfil: boolean;
  public showModalEscola:boolean;
  public diretorias:Diretoria[] = [];
  public escolas:Escola[] = [];
  public formModalEscola!:FormGroup;
  public resultPesquisa: boolean = false;
  public get form() { return this.formModalEscola.controls; }

  public ngOnInit(){

    this.formModalEscola = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required])
    });
  }

  public closeModal() {
    this.showModalAlterarPerfil = false;
  }
  public closeModalEscola() {
    this.showModalEscola = false;
  }

  public async OpenModalChangePerfil() {
    if(this.usuarioLogado){
      this.spinnerService.show();
      this.usuario = this.usuarioLogado;
      await this.getAtribuicoes();

      this.spinnerService.hide();
    }
  }

  public async getAtribuicoes() {
    this.spinnerService.show();
    var response  = await lastValueFrom(this.perfilService.getPerfisUsuario(this.usuario.perfis));
    this.usuario.perfis = response.data;

    if (this.usuario?.perfis?.length == 0) {

      this.toastrService.error('Este login não está autorizado a acessar o CONVIVA. Não tem Perfis na SED.', 'CONVIVA');
      this.toastrService.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');

      setTimeout(async () => {
          await this.oauthService.loadDiscoveryDocument();
          this.authService.cleanStorageGovBr();
          this.authService.cleanStorageConviva();
          this.oauthService.logOut();
          return;
      }, 6000);
    }

    if(this.usuario?.perfis?.length > 1){
      this.showModalAlterarPerfil = true;
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
  }

  public async onClickPerfil(cdUsuario: number, codigoPerfil: number,codigoComportamento:number) {
    this.spinnerService.show();
    var response = await lastValueFrom(this.authService.getAtribuicoesPerfil(cdUsuario, codigoPerfil));

    if(response.statusCode == 401) {
      this.toastrService.error('Este login não está autorizado a acessar o CONVIVA.', 'CONVIVA');
      this.toastrService.error('Em 5 segundos iremos te redirecionar para o login.', 'CONVIVA');
      this.spinnerService.hide();
      this.authService.cleanStorageGovBr();
      this.authService.cleanStorageConviva();
      await this.oauthService.loadDiscoveryDocument();
      this.oauthService.logOut();
    }

    if(response.statusCode == 200) {

      this.setPerfil(codigoPerfil);
      this.setDadosPerfilUnico(response.DadosUsuario);
      this.getDiretorias();
      this.getFuncionalidades(codigoPerfil);
    }



    if(response.statusCode == 201){
      this.setPerfil(codigoPerfil);
      this.getDiretoriasCombo();
      this.getFuncionalidades(codigoPerfil);
    }
  }

  public getFuncionalidades(codigoPerfil: number) {

    this.perfilService.getFuncionalidadesPerfil(codigoPerfil).subscribe({
      next: async (response: DefaultResponse) => {
        this.usuarioLogado!.funcionalidades = response.data;
        this.spinnerService.hide();
        this.showModalAlterarPerfil = false;
        this.showModalEscola = true;
        if(this.usuarioLogado!.DadosUsuario[0]?.NumeroComportamento == 3 || this.usuarioLogado!.DadosUsuario[0]?.NumeroComportamento == 4)
          this.getDiretoriasPerfilEscola(this.usuarioLogado!.DadosUsuario);
      },
      error: (error) => {
        this.ConvivaErrors.handleError(error);
        this.spinnerService.hide();
      }
    });
  }

  public getDiretoriasCombo() {
    this.spinnerService.show();

    this.escolaService
      .getDiretoriaPorTipoDiretoria()
      .subscribe({
        next: (response: DefaultResponse) => {
          if(!response.isSucess){
            this.toastrService.error(response.message);
          }
          this.diretorias = response.data;
          this.spinnerService.hide();
        },
        error: (error) => {
          this.ConvivaErrors.handleError(error);
          this.spinnerService.hide();
        },
    });
  }

  public async getDiretorias() {
    this.diretorias = [];
    this.escolas = [];
    this.usuarioLogado?.DadosUsuario?.forEach((item: AtribuicaoLoginSed) => {

      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      if(this.usuarioLogado?.codigoDiretoria != undefined && this.usuarioLogado.codigoEscola != undefined)
      {
        if(this.usuarioLogado?.codigoDiretoria == diretoria.codigoDiretoria)
          this.diretorias.push(diretoria);
      }
      else
        this.diretorias.push(diretoria);

        if(item.CodigoEscola && item.NomeEscola){
            let escola = new Escola();
            escola.codigoEscola = item.CodigoEscola;
            escola.nomeEscola = item.NomeEscola;
            escola.codigoDiretoria = item.CodigoDiretoria;
            if(this.usuarioLogado?.codigoDiretoria != undefined && this.usuarioLogado.codigoEscola != undefined)
            {
              if(this.usuarioLogado?.codigoDiretoria == diretoria.codigoDiretoria)
                this.escolas.push(escola);
            }
            else
              this.escolas.push(escola);
        }

    });



    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }

    if(this.diretorias.length == 1 && this.escolas.length == 0){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      await this.getEscolas();
    }

  }

  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.usuarioLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.getEscolas();
    }
  }

  public getDiretoriasPerfilEscola(atribuicoes: AtribuicaoLoginSed[]) {
    this.diretorias = [];
    this.escolas = [];

    atribuicoes?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();
    }
    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
  }

  public getEscolas() {

    const codigoDE = this.formModalEscola.get('codigoDiretoria');
    if(this.usuarioLogado!.DadosUsuario[0]?.NumeroComportamento == 1 ||
      this.usuarioLogado!.DadosUsuario[0]?.NumeroComportamento == 2){
      //this.zerarEscola();
      if (codigoDE?.value != null) {
        this.spinnerService.show();

        this.escolaService
          .getEscolasPorCodigoDiretoria(codigoDE?.value)
          .subscribe({
            next: (response: DefaultResponse) => {
              if(!response.isSucess){
                this.toastrService.error(response.message);
              }
              this.escolas = response.data;
              this.spinnerService.hide();
            },
            error: (error) => {
              this.ConvivaErrors.handleError(error);
              this.spinnerService.hide();
            },
          });
      }
    }
  }

  public SelecionarEscola() {

    if (this.formModalEscola.invalid) {
      this.toastrService.error('Favor de preencher a Diretoria e a Escola.', 'CONVIVA');
      return;
    }

    this.usuarioLogado!.codigoDiretoria = this.formModalEscola.get('codigoDiretoria')?.value == null ? 0 : this.formModalEscola.get('codigoDiretoria')?.value;
    this.usuarioLogado!.codigoEscola =  this.formModalEscola.get('codigoEscola')?.value == null ? 0 : this.formModalEscola.get('codigoEscola')?.value;
    this.authService.setUser(this.usuarioLogado!);
    this.showModalEscola = false;
    window.location.reload();
  }



  public setDadosPerfilUnico(atribuicoes:Atribuicao[]){
    var novosDadosUsuarios:AtribuicaoLoginSed[] = [];
    var dadosUsuario = this.usuarioLogado!.DadosUsuario[0];

    atribuicoes.forEach((item:Atribuicao)=>{
      let newAtribuicao = new AtribuicaoLoginSed();

      //Dados atribuições
      newAtribuicao.CodigoDiretoria = item.CodigoDiretoria;
      newAtribuicao.NomeDiretoria = item.NomeDiretoria;
      newAtribuicao.CodigoTurma = item.CodigoTurma;
      newAtribuicao.NomeEscola = item.NomeEscola;
      newAtribuicao.CodigoEscola = item.CodigoEscola;

      //Dados cabeçalho
      newAtribuicao.NumeroComportamento = dadosUsuario.NumeroComportamento;
      newAtribuicao.Comportamento = dadosUsuario.Comportamento;
      newAtribuicao.CodigoPerfil = dadosUsuario.CodigoPerfil;
      newAtribuicao.NomePerfil = dadosUsuario.NomePerfil;
      newAtribuicao.Cpf = dadosUsuario.Cpf;
      newAtribuicao.Email = dadosUsuario.Email;
      newAtribuicao.Login = dadosUsuario.Login;
      newAtribuicao.CodigoUsuario = dadosUsuario.CodigoUsuario;
      newAtribuicao.DataInclusao = dadosUsuario.DataInclusao;
      newAtribuicao.Nome = dadosUsuario.Nome;

      novosDadosUsuarios.push(newAtribuicao);
    });

    this.usuarioLogado!.DadosUsuario = novosDadosUsuarios;
  }

  public async getLoginPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPsicologoPorCPF(this.usuario.cpf));
    if(!response.isSucess){
      this.toastrService.error(response.message);

      setTimeout(async () => {
        await this.oauthService.loadDiscoveryDocument();
        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.oauthService.logOut();
        this.spinnerService.hide();
      }, 3000);

      return;
    }

    if(response.data == null){
      this.toastrService.error("Psicólogo sem acesso ao Conviva.");

      setTimeout(async () => {
        await this.oauthService.loadDiscoveryDocument();
        this.authService.cleanStorageGovBr();
        this.authService.cleanStorageConviva();
        this.oauthService.logOut();
        this.spinnerService.hide();
      }, 3000);

    }
    this.PopulaPsicologo(response.data);
    await this.getFuncionalidade(this.usuarioLogado!.perfis[0].codigoPerfil);
    await this.getDiretoriasPorPolo(this.usuarioLogado!.codigoPolo);
    this.MostraModalEscola()
    this.authService.setUser(this.usuarioLogado!);
    this.spinnerService.hide();

  }

  private MostraModalEscola(){
    this.showModalAlterarPerfil = false;
    this.showModalEscola = true;
  }

  private PopulaPsicologo(userData:any){

    this.usuarioLogado!.flPsicologo = true;
    this.usuarioLogado!.codigoPolo = userData.codigoPoloRegional;
    this.usuarioLogado!.NumeroComportamento = '3';
    this.usuarioLogado!.comportamento = 'Escola';
    this.usuarioLogado!.nomePerfil = 'Psicólogo';
    this.usuarioLogado!.codigoPerfil = Perfil.Psicólogo;

    var atribuicao = this.usuarioLogado!.DadosUsuario[0];
    atribuicao.CodigoPolo = userData.codigoPoloRegional;
    atribuicao.NumeroComportamento = 3;
    atribuicao.Comportamento = 'Escola';
    atribuicao.CodigoPerfil = Perfil.Psicólogo;
    this.usuarioLogado!.DadosUsuario = [atribuicao];
  }

  public async getFuncionalidade(codigoPerfil: number){
    var response = await lastValueFrom(this.perfilService.getFuncionalidadesPerfil(codigoPerfil));
    if(!response.isSucess){
      this.toastrService.error(response.message);
      return;
    }
    this.usuarioLogado!.funcionalidades = response.data;

  }

  private async getDiretoriasPorPolo(codigoPolo:number){
    var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(codigoPolo));
    if(!response.isSucess){
      this.toastrService.error(response.message)

      setTimeout(() => {
        this.router.navigate(['/login']);
        this.spinnerService.hide();
      }, 5000);
    }

    this.diretorias = response.data;
    this.form.codigoDiretoria.enable();
    return;
  }
  private setPerfil(cdPerfil:number){
    var perfilSelecionado = this.usuario.perfis.find((item:PerfilLoginSed)=> item.codigoPerfil == cdPerfil)
    this.usuarioLogado!.DadosUsuario[0].NumeroComportamento = perfilSelecionado!.codigoComportamento;
    this.usuarioLogado!.DadosUsuario[0].NomePerfil = perfilSelecionado!.descricaoPerfil;
    this.usuarioLogado!.DadosUsuario[0].CodigoPerfil = perfilSelecionado!.codigoPerfil;
    this.usuarioLogado!.DadosUsuario[0].Comportamento = perfilSelecionado!.descricaoComportamento;
    this.usuarioLogado!.nomePerfil = perfilSelecionado!.descricaoPerfil;
    this.usuarioLogado!.codigoPerfil = perfilSelecionado!.codigoPerfil;
    this.usuarioLogado!.NumeroComportamento = perfilSelecionado!.codigoComportamento.toString();
    // this.usuarioLogado!.cpf = String(this.usuarioLogado!.DadosUsuario[0]!.Cpf);
  }
}
