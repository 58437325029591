
<section class="section-calendario">
  <div class="container">

    <!-- data-periodo -->
    <section class="section-data-periodo">
      <div class="container">
        <div class="periodo">
          <p><b>Período do calendário:</b></p>
          <div class="periodo-input">
            <input
            type="date"
            name="dataInicial"
            formControlName="dataInicio"
            />
            -
            <input
            type="date"
            name="dataFinal"
            formControlName="dataFim"
            />
          </div>
        </div>
      </div>
    </section><!-- Final data-periodo -->

    <div class="header-calendario">
      <button (click)="goToPreviousMonth()" class="icone voltar-mes ">Mês Anterior</button>
      <h2>{{ currentDate | date: 'MMMM yyyy' }}</h2>
      <!-- {{ formatarMes(currentDate) }} {{ currentDate | date: 'yyyy' }}
      <input type="date" [(ngModel)]="currentDate" (ngModelChange)="onDateChange($event)" /> -->
      <button (click)="goToCurrentMonth()" class="icone avancar-mes ">Mês Atual</button>
    </div>
    <div class="dias">
      <div *ngFor="let dia of daysInMonth" class="dia" [ngClass]="{ 'dia-atual': isCurrentDay(dia.date), 'indisponivel': dia.isWeekend || dia.isHoliday }">
        <p class="dia-semana">{{ dia.date | date: 'EEE' }}</p>
        <p class="numero">{{ dia.date | date: 'dd' }}</p>
      </div>
    </div><!-- dias -->

    <div class="container legenda-calendario">
      <p><b>Legenda: </b> Calendário</p>
      <div class="conteudo-legenda">

        <div class="box-legenda dia-atual">
          <i class="icone-dot"></i> <p>Dia Atual</p>
        </div>
        <div class="box-legenda dia-sem-ocorrencia">
          <i class="icone-dot"></i> <p>Dia sem ocorrência</p>
        </div>
        <div class="box-legenda dia-com-ocorrencia">
          <i class="icone-dot"></i> <p>dia com ocorrência</p>
        </div>
        <div class="box-legenda dia-sem-marcacao">
          <i class="icone-dot"></i> <p>dia sem marcação</p>
        </div>
        <div class="box-legenda indisponivel">
          <i class="icone-dot"></i> <p>indisponível</p>
        </div>

      </div>
    </div> <!--legenda-calendario -->
  </div>
</section><!-- section-calendario -->
