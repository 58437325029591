<form [formGroup]="formDashboard" (submit)="PesquisarChamados()">
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro" >
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="OnChangePolo()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }"

        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>

        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>

      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="onChangeEscola()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
      </div>


      <div class="grupo-filter">
        <p><b>Categoria</b></p>
        <div class="conteudo-select">
          <ng-select
            [items]="categorias"
            bindLabel="descricaoSubCategoria"
            bindValue="codigoSubCategoria"
            groupBy="descricaoCategoria"
            placeholder="Selecione uma Sub Categoria"
            formControlName="codigoSubCategoria"
            name="codigoSubCategoria"
            [ngClass]="{
              'is-invalid': submitted && form.codigoSubCategoria.errors
            }"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.descricaoCategoria || "Unnamed group" }}
            </ng-template>
          </ng-select>

          <div *ngIf="submitted && form.codigoSubCategoria.errors" class="alert">
            <div *ngIf="form.codigoSubCategoria.errors.required">
              * Sub Categoria é obrigatório
            </div>
          </div>
        </div>
      </div>


      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Chamados"
          title="Pesquisar Chamados"
        />
      </button>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Agendamento Psicólogos</h2>

      <div class="periodo">
        <p><b>Pesquisar por período:</b></p>
        <div class="periodo-input">
          <input
          type="date"
          name="dataInicial"
          min="{{minDate}}"
          max="{{maxDate}}"
          (blur)="validaAno(this.form.dataInicio)"
          formControlName="dataInicio"
          />
          &nbsp;-&nbsp;
          <input
          type="date"
          name="dataFinal"
          min="{{minDate}}"
          max="{{maxDate}}"
          (blur)="validaAno(this.form.dataFim)"
          formControlName="dataFim"
          />
        </div>
      </div>

    </div>
  </section>

  <!-- RESULTADOS -->
  <section class="conteudo-principal">

      <div class="container">

        <div class="conteudo-perfil"   [hidden]="!this.submitted || !this.showPesquisa">

          <div class="aviso-agendamentos" *ngIf="quantidadeAgendamentosAbertos > 0">
            <span>Há um total de {{quantidadeAgendamentosAbertos}} encaminhamentos sem agendamentos. </span>
          </div>
          <h3 class="sub-titulo-dash">
            Listagem de Chamados
          </h3>

            <app-tabulator-table [tableData]="lstChamados" [columnNames]="colunasGrid" [tableTitle]="titleExport" [tipoRelatorio]="2" [orientacaoPortrait]="false"></app-tabulator-table>


        </div>

      </div>

  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalAgendamento">
  <form
  class="container-modal form-registro modal-envolvidos"
  [formGroup]="formAgendamento"
  (submit)="AgendarAtendimento()"
  >
    <div class="header-modal">
      <h1 class="title-ocorrencia">Visualização de Ocorrência</h1>
        <a (click)="FecharModalAgendamento()"
        >
          <i class="fa-solid fa-x"></i>
        </a>
    </div>
    <hr/>

    <strong>Chamado: {{agendamentoAtual!.codigoChamado}}</strong>
    <div class="descricao-modal-agendamento">
        <p><i class="fa-solid fa-calendar-days"></i><strong>Data Abertura: &nbsp;</strong>  {{getDate(agendamentoAtual!.dataAbertura)}}</p>
        <p><i class="fa-solid fa-user"></i><strong>Nome Envolvido:&nbsp; </strong> {{agendamentoAtual!.nomeEnvolvido}}</p>
        <p><i class="fa-solid fa-play"></i><strong>Tipo Envolvimento: &nbsp;</strong> {{agendamentoAtual!.tipoEnvolvimento}}</p>
        <p><i class="fa-solid fa-play"></i><strong>Ocorrência:&nbsp; </strong> {{agendamentoAtual!.ocorrencia}} &nbsp; <strong>Status:</strong> {{agendamentoAtual!.status}}</p>
        <p><i class="fa-solid fa-graduation-cap" ></i><strong>Polo: &nbsp;</strong> {{agendamentoAtual!.polo}}</p>
        <p><i class="fa-solid fa-graduation-cap" ></i><strong>Diretoria de Ensino: &nbsp;</strong> {{agendamentoAtual!.diretoria}}</p>
        <p><i class="fa-solid fa-graduation-cap" ></i><strong>Escola: &nbsp;</strong> {{agendamentoAtual!.escola}}</p>
        <p><i class="fa-solid fa-calendar-days"></i><strong>Data e Horário: &nbsp;</strong> {{getDataAtendimento(agendamentoAtual!.dataAtendimento)}}<p>
    </div>
      <strong>Agendamento</strong>
    <hr>
    <div>
      <div class="inputs-form">
        <i class="fa-solid fa-calendar-days"></i>
        <strong >Data e Horario do Atendimento:</strong> &nbsp;

        <input
          class="input_style"
          type="date"
          formControlName="dataAgendada"
          *ngIf="DataAtendimento == undefined"
          >

        <p *ngIf="DataAtendimento != undefined">{{getDate(DataAtendimento)}}</p>
        &nbsp;-&nbsp;
        <input
          class="input_style"
          type="time"
          formControlName="horarioAgendado"
          *ngIf="HoraAtendimento == undefined"
        >
        <p *ngIf="HoraAtendimento != undefined">{{getHour(HoraAtendimento)}}</p>


      </div>
      <div *ngIf="submittedModal && formAg.dataAgendada.errors" class="alert">
        <div *ngIf="formAg.dataAgendada.errors.required">
          * Data e Horario são obrigatórios
        </div>
      </div>
      <div class="inputs-form">
        <i class="fa-solid fa-user"></i>
        <strong >Psicólogo:</strong>
        &nbsp;
        <p *ngIf="NomePsicologo != undefined">{{NomePsicologo}}</p>
        <ng-select
          [items]="lstPsicologos"
          bindLabel="nomePsicologo"
          bindValue="codigoCPF"
          placeholder="Selecione um Psicólogo"
          name="psicologo"
          formControlName="psicologo"
          *ngIf="NomePsicologo == undefined"
          [ngClass]="{
            'is-invalid': submittedModal && formAg.psicologo.errors
          }"
        >
          <!-- <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template> -->
        </ng-select>

        <div *ngIf="submittedModal && formAg.psicologo.errors" class="alert">
          <div *ngIf="formAg.psicologo.errors.required">
            * Psicólogo obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="container-btns">
      <input type="button" (click)="AbreFechaModalConfirmacao()" [disabled]="DesabilitaBotaoExcluirAgendamento" class="btn_outline" value="Excluir"/>
      <input type="submit"  class="btn_primary" value="Agendar" [disabled]="this.agendamentoAtual!.psicologoCPF"/>
    </div>
  </form>
</app-modal>

<app-modal *ngIf="showModalConfirmacao">
  <div class="container-modal form-registro modal-envolvidos">
    <h1 class="title-ocorrencia">Excluir</h1>
    <div>
      <p>Deseja excluir registro?</p>
    </div>
    <div class="container-btns">
      <input type="button" (click)="AbreFechaModalConfirmacao()" class="btn_outline" value="Não"/>
      <input type="submit" (click)="ExcluirAgendamento(agendamentoAtual!.codigoChamado,agendamentoAtual!.codigoEnvolvido)" class="btn_primary" value="Sim" />
    </div>
  </div>
</app-modal>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

<app-modal *ngIf="showModalAtendimentoPsicologo">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Atendimento do Psicólogo</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span *ngIf="!showDataAtendimento">{{ this.descricaoAtendimento }}</span>
      <form [formGroup]="formAtendimento">
        <span *ngIf="showDataAtendimento"> Quando ocorreu esse atendimento?</span>
        <input
            type="datetime-local"
            class="input-date"
            *ngIf="showDataAtendimento"
            name="dataAtendimento"
            formControlName="dataAtendimento"
            />
      </form>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalAtendimento()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="button"
        (click)="ShowInputDataAtendimento()"
        *ngIf="!showDataAtendimento"
        class="btn_primary"
        value="Sim"
      />
      <input
        type="button"
        *ngIf="showDataAtendimento"
        (click)="AtenderChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>
