import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { formatDate } from '@angular/common';
import { FormGroup } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { ListarEncaminhados } from "src/app/shared/models/chamados/listar-encaminhados";
import { UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { NotificacoesService } from "src/app/shared/services/notificacoes.service";
import { SignalRService } from "src/app/shared/services/signalr.service";


@Component({
  selector: 'calendario-component',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {
  currentDate: string;
  daysInMonth: { date: Date, isWeekend: boolean, isHoliday: boolean }[] = [];

  constructor(){
  }

  ngOnInit(): void {
    this.currentDate = new Date().toISOString().slice(0, 10); // Data no formato 'YYYY-MM-DD'
    this.generateDaysInMonth();
  }

  generateDaysInMonth() {
    const date = new Date(this.currentDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    this.daysInMonth = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const day = new Date(year, month, i);
      const isWeekend = day.getDay() === 0 || day.getDay() === 6; // sábado (0) ou domingo (6)
      const isHoliday = this.isHoliday(day); // Verifica se é um feriado (implemente sua lógica para isso)

      this.daysInMonth.push({ date: day, isWeekend, isHoliday });
    }
  }


  goToPreviousMonth() {
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() - 1);
    this.currentDate = date.toISOString().slice(0, 10);
    this.generateDaysInMonth();
  }

  goToCurrentMonth() {
    this.currentDate = new Date().toISOString().slice(0, 10);
    this.generateDaysInMonth();
  }

  isCurrentDay(day: Date): boolean {
    const today = new Date();
    return day.getFullYear() === today.getFullYear() &&
           day.getMonth() === today.getMonth() &&
           day.getDate() === today.getDate();
  }

  isHoliday(day: Date): boolean {
    // Implemente a lógica para verificar se o dia é um feriado
    // Retorne true se for um feriado, caso contrário, retorne false
    // Por exemplo:
    // return (day.getDate() === 25 && day.getMonth() === 11); // Natal (25 de dezembro)
    return false; // Por enquanto, não consideramos nenhum feriado
  }

  onDateChange(event: any) {
    this.generateDaysInMonth();
  }

  formatarMes(date: string): string {
    const mes = new Date(date);
    return formatDate(mes, 'LLLL', 'pt');
  }

  formatarDiaSemana(date: Date): string {
    return formatDate(date, 'EEEE', 'pt-br');
  }
}
