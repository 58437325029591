<form [formGroup]="formCadastrar">
  <!-- (submit)="AprovarAtividade()" -->

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Aprovação Atividade Professor POC</h2>
      <app-btn-back></app-btn-back>
    </div>
  </section>

  <section class="container-conviva form-registro">
    <!-- VISUALIZACAO GERAL -->
    <div class="container form-vista-psicologo visualizacao-geral">
      <div class="grupo-filter-full">
        <label>Professor Orientador de Convivência (POC):</label>
        <p>{{ this.atividade.nM_POC }}</p>
      </div>

      <div class="conteudo-row">
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Data da Visita:</label>
            <p>{{ getDate(this.atividade.dT_ATIVIDADE_POC) }}</p>
          </div>
        </div>
        <div class="box-duas-colum">
          <div class="grupo-filter-full">
            <label>Quanto tempo durou a ação?</label>
            <p>{{ this.atividade.tM_ATIVIDADE_POC }}MN</p>
          </div>
        </div>
      </div>

      @if(this.atividade.tP_ACAO_ATIVIDADE === 4){
      <div class="grupo-filter-full">
        <label>Ocorrências agendadas:</label>
        <p>
          Chamado {{ this.atividade.cD_CHAMADO }} -
          {{ this.atividade.dS_SUB_CATEGORIA }} -
          {{ this.atividade.statuS_CHAMADO }}
        </p>
      </div>

      }

      <div class="grupo-filter-full">
        <label>Qual atividade foi realizada?</label>
        <p>{{ this.atividade.atividadE_REALIZADA }}</p>
      </div>

      <div
        class="conteudo-envolvidos"
        *ngIf="
          this.atividade.tP_ATIVIDADE_REALIZADA == 1 &&
          this.atividade.cD_ENVOLVIDO != null
        "
      >
        <h3>Envolvido</h3>
        <div class="envolvidos-aluno">
          <div
            class="perfil-aluno-chamado"
            *ngFor="let envolvido of lstEnvolvidos"
          >
            <div class="img-aluno">
              <span>{{ envolvido.iniciais }}</span>
            </div>
            <div class="infor-aluno-chamado">
              <p class="nome-aluno">{{ envolvido.nome }}</p>
              <p class="descricao">
                {{ envolvido.dsTipoPessoa }} - {{ envolvido.dsEnvolvido }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="conteudo-envolvidos"
        *ngIf="this.atividade.tP_ATIVIDADE_REALIZADA == 2"
      >
        <h3>Turmas</h3>
        <div class="grid-datatable">
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            class="row-border"
            aria-describedby="dashboard"
          >
            <thead>
              <tr>
                <th>Codigo Turma</th>
                <th>Numero Classe</th>
                <th>Tipo de Ensino</th>
                <th>Numero de Serie</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstTurmas">
                <td>{{ item.cD_TURMA }}</td>
                <td>{{ item.nR_CLASSE }}</td>
                <td>{{ item.dS_TURMA }}</td>
                <td>{{ item.nR_SERIE }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="grupo-filter-full">
        <label>Qual o objetivo da ação?</label>
        <p>{{ this.atividade.dS_RESPOSTA_PERGUNTA_OBJETIVO_ACAO }}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Como você avalia o resultado da ação realizada?</label>
        <p>
          {{ this.atividade.dS_RESPOSTA_PERGUNTA_AVALIACAO_RESULTADO_ACAO }}
        </p>
      </div>

      <div class="grupo-filter-full">
        <label
          >Como você acha que o estudante recebeu e foi impactado pela ação
          realizada?</label
        >
        <p>{{ this.atividade.dS_RESPOSTA_PERGUNTA_IMPACTO_ACAO_REALIZADA }}</p>
      </div>

      <div class="grupo-filter-full">
        <label>Anexos</label>
        @for (item of this.atividade.arquivos; track $index) {
        <a
          href="javascript:void(0)"
          class="icon-arquivo no-wrap"
          (click)="abrirEmNovaAba(item.caminhoAzure)"
          style="white-space: nowrap"
        >
          {{ getShortenedName(item.nome) }}
        </a>
        }
      </div>
    </div>
    <!-- END VISUALIZACAO GERAL -->
  </section>

  <section class="container-conviva pt-5 mt-3">
    <div class="container">
      <strong class="titulo-aprovacao"
        >Aprovação PEC - Professor Especialista em currículo</strong
      >
      <div class="form-aprovacao-diretor">
        <div class="grupo-filter-full">
          <label>
            Parabéns! A sua ação foi muito boa e certamente ajudará bastante na
            melhoria do clima e convivência escolar.
            <input
              type="checkbox"
              id="checkbox1"
              (change)="onChange1()"
              [(ngModel)]="checkbox1"
              [disabled]="disableInputsPEC"
              [ngModelOptions]="{ standalone: true }"
            />
          </label>
        </div>

        <div class="grupo-filter-full">
          <label>
            Temos muitas coisas legais na sua ação! Mas gostaria de fazer
            algumas sugestões para as próximas, tudo bem?
            <input
              type="checkbox"
              id="checkbox2"
              (change)="onChange2()"
              [(ngModel)]="checkbox2"
              [disabled]="disableInputsPEC"
              [ngModelOptions]="{ standalone: true }"
            />
          </label>
        </div>

        <div class="grupo-filter-full">
          <label>
            Acredito que esta ação pode não ter sido muito muito efetiva. Vamos
            repensá-la e refazê-la?
            <input
              type="checkbox"
              (change)="onChange3()"
              id="checkbox3"
              [(ngModel)]="checkbox3"
              [disabled]="disableInputsPEC"
              [ngModelOptions]="{ standalone: true }"
            />
          </label>
        </div>

        @if(showTextAreaPEC()){
        <div class="textarea-container">
          <textarea
            placeholder="Responda aqui"
            rows="5"
            cols="33"
            formControlName="dsAprovar"
            placeholder="Descreva a atividade com um minimo de 150 caracteres e um máximo de 500."
            name="dsAprovar"
            (keyup)="onChangeTextAreaPEC()"
          >
          </textarea>
        </div>
        @if(!this.atividade.sT_APROVACAO_PEC){
        <small>Quantidade de caracteres atuais: {{ textAreaPECCount }}</small>
        } }

        <star-rating-conviva
          [checkboxNumber]="checkboxNumberPEC"
          [(rating)]="ratingPEC"
          (ratingChange)="getRatingPEC($event)"
          [isDisabled]="disableInputsPEC"
        >
        </star-rating-conviva>

        <div class="container-btns-visitas">
          @if(!this.atividade.sT_APROVACAO_PEC){
          <input
            type="button"
            class="btn_primary"
            [disabled]="disableInputsPEC"
            (click)="AprovarAtividadePEC()"
            value="{{ this.checkbox3 ? 'Reprovar' : 'Aprovar' }}"
          />
          }
        </div>
      </div>
    </div>
  </section>

  <section class="container-conviva pt-1 mt-4">
    <div class="container">
      <strong class="titulo-aprovacao">Aprovação Diretor</strong>
      <div class="form-aprovacao-diretor">
        <div class="grupo-filter-full">
          <label>
            Parabéns! A sua ação foi muito boa e certamente ajudará bastante na
            melhoria do clima e convivência escolar.
            <input
              type="checkbox"
              id="CheckBoxDiretor1"
              formControlName="CheckBoxDiretor1"
            />
          </label>
        </div>

        <div class="grupo-filter-full">
          <label>
            Temos muitas coisas legais na sua ação! Mas gostaria de fazer
            algumas sugestões para as próximas, tudo bem?
            <input
              type="checkbox"
              id="CheckBoxDiretor2"
              formControlName="CheckBoxDiretor2"
            />
          </label>
        </div>

        <div class="grupo-filter-full">
          <label>
            Acredito que esta ação pode não ter sido muito muito efetiva. Vamos
            repensá-la e refazê-la?
            <input
              type="checkbox"
              id="CheckBoxDiretor3"
              formControlName="CheckBoxDiretor3"
            />
          </label>
        </div>

        @if(showTextAreaDiretor()){
        <div class="textarea-container">
          <textarea
            placeholder="Responda aqui"
            rows="5"
            cols="33"
            formControlName="dsAprovarDiretor"
            placeholder="Descreva a atividade com um minimo de 150 caracteres e um máximo de 500."
            name="dsAprovarDiretor"
            (keyup)="onChangeTextAreaDiretor()"
          >
          </textarea>
        </div>
        @if(!this.atividade.sT_APROVACAO_DIRETORIA){
        <small>Quantidade de caracteres atuais: {{textAreaDiretorCount}}</small>
        } }

        <star-rating-conviva
          [checkboxNumber]="checkboxNumberDiretor"
          [(rating)]="ratingDiretor"
          (ratingChange)="getRatingDiretor($event)"
          [isDisabled]="disableInputsDiretor"
        >
        </star-rating-conviva>

        <div class="container-btns-visitas">
          @if(!this.atividade.sT_APROVACAO_DIRETORIA){
          <input
            type="button"
            class="btn_primary"
            [disabled]="disableInputsDiretor"
            (click)="AprovarAtividadeDiretor()"
            value="{{
              this.form.CheckBoxDiretor3.value == true ? 'Reprovar' : 'Aprovar'
            }}"
          />
          }
        </div>
      </div>
    </div>
  </section>
</form>
<!-- Modal -->
<app-modal *ngIf="showModalConfirmacao">
  <div class="container-modal form-registro modal-envolvidos">
    <h1 class="title-ocorrencia">Excluir</h1>
    <div>
      <p>Deseja fechar o chamado?</p>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="AbreFechaModalConfirmacao()"
        class="btn_outline"
        value="Não"
      />
      <input
        type="submit"
        (click)="ConcluirChamado()"
        class="btn_primary"
        value="Sim"
      />
    </div>
  </div>
</app-modal>
