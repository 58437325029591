export class Envolvido {
  tipoEnvolvido:number;
  codigoAluno?: number;
  tipoPessoa?: number;
  dsTipoPessoa?:string;
  cpf?:string;
  ra?:string;
  nome?: string;
  dsOutros?:string;
  imagemTemporaria?: string;
  idTemporario?: number;
  codigoTurma?: number;
  descricaoTurma?: string;
  cdEnvolvido?: number;
  codigoEnvolvido?: number;
  foto?:string;
  temFoto?:boolean;
  iniciais?:string;
  status?:string;
  statusS?:string;
  dsEnvolvido?:string;
  escola?:string;
  numeroClasse?: number;
  nomeTipoEnsino?: string;
  numeroSerie?: number;
  dsEstatusEnvolvido?: string;
  }
