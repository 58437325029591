<form [formGroup]="formCadastrar" (submit)="OnSalvar()">
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
          (change)="getEscolas()"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="getTurnos()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>






    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Adicionar Não Ocorrência</h2>
    </div>
  </section>

  <!-- DETALHES CHAMADO -->
  <section class="conteudo-principal">
    <div class="container-conviva form-registro">

      <!-- INFO DO CHAMADO -->
      <div class="time-line conteudo-registro">

        <div class="conteudo-select">
          <label for="select-gravidade">Data da Ocorrência</label>
          <input
          type="datetime-local"
          name="dataChamado"
          class="input-date"
          formControlName="dataChamado"
          />
        </div>

        <div class="conteudo-select">
          <label for="select-categoria">Categoria</label>
          <ng-select
            [items]="categorias"
            bindLabel="descricaoSubCategoria"
            bindValue="codigoSubCategoria"
            groupBy="descricaoCategoria"
            placeholder="Selecione uma Subcategoria"
            formControlName="codigoSubCategoria"
            name="codigoSubCategoria"
            [ngClass]="{
              'is-invalid': submitted && form.codigoSubCategoria.errors
            }"
          >
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.descricaoCategoria || "Unnamed group" }}
            </ng-template>
          </ng-select>

          <div *ngIf="submitted && form.codigoSubCategoria.errors" class="alert">
            <div *ngIf="form.codigoSubCategoria.errors.required">
              * Sub Categoria é obrigatório
            </div>
          </div>
        </div>

        <div class="conteudo-select">
          <label for="select-gravidade">Gravidade</label>
          <ng-select
            [items]="gravidades"
            bindLabel="name"
            bindValue="id"
            class="select2-single"
            placeholder="Selecione uma Gravidade"
            formControlName="codigoGravidade"
            name="codigoGravidade"
            [ngClass]="{
              'is-invalid': submitted && form.codigoGravidade.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoGravidade.errors" class="alert">
            <div *ngIf="form.codigoGravidade.errors.required">
              * Gravidade é obrigatório
            </div>
          </div>
        </div>

        <div class="conteudo-textarea">
          <label for="text-registro">Faça uma breve descrição de como foi o dia escolar.</label>
          <textarea
            id="text-registro"
            name="text-registro"
            rows="6"
            cols="50"
            formControlName="dsChamado"
            name="dsChamado"
            minlength="50"
            (keyup)="OnChangeCountLetters()"
            [ngClass]="{ 'is-invalid': submitted && form.dsChamado.errors }"
          ></textarea>
          <p>Quantidade de caracteres atual: {{qtdeCaracteresDescricao}}</p>
          <div *ngIf="submitted && form.dsChamado.errors" class="alert">
            <div *ngIf="form.dsChamado.errors?.required">
              * Descrição da não ocorrência é obrigatório.
            </div>
            <div *ngIf="form.dsChamado.errors?.minlength">
              * É obrigatório no mínimo digitar 50 caracteres.
            </div>
            <div *ngIf="form.dsChamado.errors?.maxlength">
              * Maximo de caracteres é 500.
            </div>
          </div>
        </div>



        <div class="container-btns">
          <input type="button" class="btn_outline" id="btnLimpar" name="b2" value="Limpar" (click)="onLimpar()"/>
          <input type="submit" class="btn_primary" value="Salvar" />
        </div>
      </div>


    </div>
  </section>
</form>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>

